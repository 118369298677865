/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import { useSelector } from 'react-redux'

import Page4First from '@/modules/medical-record/assets/images/page4first.jpg'
import Page4Second from '@/modules/medical-record/assets/images/page4second.jpg'
import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'

const Page4: React.FC = () => {
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)

  if (!data) return <div>Không có dữ liệu</div>

  return (
    <div className='w-full'>
      <div className='mt-4 flex'>
        <span>Liên kết đến biểu đồ trực tuyến:</span>
        {data?.additionalLink && (
          <a href={data.additionalLink} className='ms-2 text-primary underline hover:text-primary-200' target='_blank' rel='noopener noreferrer'>
            {data?.additionalLink}
          </a>
        )}
      </div>
      {/* table 1 */}
      <table className='mt-4 w-full table-fixed border-collapse'>
        <tbody>
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'> </td>
            <td className='border border-black/50 px-2 py-1' colSpan={13}>
              Họ và tên: {data?.patient?.name}
            </td>
            <td className='border border-black/50 px-2 py-1' colSpan={3}>
              <span>Ngày:</span>
              <span className='ml-2'>{data?.p4table1?.ngay}</span>
            </td>
          </tr>

          {/* Độ lung lay */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>Độ lung lay</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.lunglay82}</td>
          </tr>

          <tr>
            <td className='w-[120px] px-2 py-1 text-end'> </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* PII */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.pii82}</td>
          </tr>

          {/* GI */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gi82}</td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.ppd82}</td>
          </tr>

          {/* GM */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.gm82}</td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.cal82}</td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table1?.bop82}</td>
          </tr>
        </tbody>
      </table>

      <div className='my-0 w-full py-0 ps-[110px]'>
        <img src={Page4First} alt='Rang' className='w-full' />
      </div>

      {/* Table 2 */}
      <table className='w-full table-fixed border-collapse'>
        <tbody>
          {/* PII */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.pii82}</td>
          </tr>

          {/* GI */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gi82}</td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.ppd82}</td>
          </tr>

          {/* GM */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.gm82}</td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.cal82}</td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.bop82}</td>
          </tr>

          <tr>
            <td className='w-[120px] px-2 py-1 text-end'> </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* Độ lung lay */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>Độ lung lay</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table2?.lunglay82}</td>
          </tr>
        </tbody>
      </table>

      {/* Table 3 */}
      <table className='mt-10 w-full table-fixed border-collapse'>
        <tbody>
          {/* Độ lung lay */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>Độ lung lay</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.lunglay82}</td>
          </tr>

          <tr>
            <td className='w-[120px] px-2 py-1 text-end'> </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* PII */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.pii82}</td>
          </tr>

          {/* GI */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gi82}</td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.ppd82}</td>
          </tr>

          {/* GM */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.gm82}</td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.cal82}</td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table3?.bop82}</td>
          </tr>
        </tbody>
      </table>

      <div className='my-0 w-full py-0 ps-[110px]'>
        <img src={Page4Second} alt='Rang' className='w-full' />
      </div>

      {/* Table 4 */}
      <table className='w-full table-fixed border-collapse'>
        <tbody>
          {/* PII */}
          <tr>
            <td className='w-[120px] px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.pii82}</td>
          </tr>

          {/* GI */}
          <tr>
            <td className='px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gi82}</td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.ppd82}</td>
          </tr>

          {/* GM */}
          <tr>
            <td className='px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.gm82}</td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.cal82}</td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.bop82}</td>
          </tr>

          <tr>
            <td className='px-2 py-1 text-end'> </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* Độ lung lay */}
          <tr>
            <td className='px-2 py-1 text-end'>Độ lung lay</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay81}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay71}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay61}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay51}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay41}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay31}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay21}</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay11}</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay12}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay22}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay32}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay42}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay52}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay62}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay72}</td>
            <td className='border border-black/50 px-2 py-1 text-center'>{data?.p4table4?.lunglay82}</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Page4
