import type { GetProp, UploadFile, UploadProps } from 'antd'
import { App, Button, Image, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { IoAdd } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { deleteMedicalFiles, getMedicalById, updateEditState, updateMedical, uploadMedicalFiles } from '../../reducers/medicalReducer'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import Page5 from './Page5'
import Page6 from './Page6'
import Page7 from './Page7'
import Page8 from './Page8'
import Page9 from './Page9'
import Page10 from './Page10'

type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0]

const getBase64 = (file: FileType): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const EditLayout: React.FC = () => {
  const dispatch = useDispatch<any>()
  const { id } = useParams()
  const [searchParams] = useSearchParams()

  const data: MedicalFormState = useSelector((state: RootState) => state.medical.editState)
  const totalPages = 10
  const page = searchParams.get('page') || '1'
  const [loading, setLoading] = useState(true)
  const doctorId = useSelector((state: RootState) => state.medical.doctorId)
  const navigate: NavigateFunction = useNavigate()
  const { message } = App.useApp()
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const handleUpdate = async () => {
    try {
      if (data.additionalLink && !data.additionalLink.startsWith('http')) {
        message.error('Link không hợp lệ')
        return
      }

      const rs = await dispatch(
        updateMedical({
          id,
          data,
          doctorId
        })
      ).unwrap()

      const existingFiles = rs.media.map((file: any) => file.fileUrl)
      const newFiles = fileList.filter((file) => !file.url)
      const removedFiles = existingFiles
        .filter((url: string) => !fileList.some((file) => `/uploads/media/${file.name}` === url))
        .map((url: string) => url.split('/').pop())

      if (newFiles.length > 0) {
        const formData = new FormData()
        newFiles.forEach((file) => {
          formData.append('files', file.originFileObj as File)
        })
        formData.append('medicalId', rs.id)
        await dispatch(uploadMedicalFiles(formData)).unwrap()
      }

      if (removedFiles.length > 0) {
        await dispatch(deleteMedicalFiles({ fileNames: removedFiles }))
      }

      navigate(`/medical-records/${rs.id}?page=${page}`)
      message.success('Cập nhật thành công')
    } catch (error: any) {
      message.error(error.message)
    }
  }

  const fetchMedicalRecord = async () => {
    try {
      const rs = await dispatch(getMedicalById(id as string)).unwrap()
      dispatch(updateEditState(rs.data))
      if (rs?.media) {
        const formattedFileList = rs.media.map((file: any) => ({
          uid: file.id.toString(),
          name: file.fileName,
          status: 'done',
          url: process.env.REACT_APP_BASE_API_URL + file.fileUrl,
          thumbUrl: process.env.REACT_APP_BASE_API_URL + file.fileUrl
        }))
        setFileList(formattedFileList)
      }
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => setFileList(newFileList)

  const handlePreview = async (file: UploadFile) => {
    const isVideoFile = file.type?.startsWith('video')

    if (isVideoFile) {
      const videoUrl = file.url || URL.createObjectURL(file.originFileObj as FileType)
      window.open(videoUrl, '_blank')
    } else {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as FileType)
      }

      setPreviewImage(file.url || (file.preview as string))
      setPreviewOpen(true)
    }
  }

  const goToPage = (pageNumber: string) => {
    if (pageNumber === page) return
    navigate(`/medical-records/${id}/edit?page=${pageNumber}`)
  }

  const beforeUpload = (file: FileType) => {
    const isAllowedType = ['image/jpeg', 'image/png', 'image/gif', 'image/webp', 'image/bmp', 'image/jpg', 'video/mp4'].includes(file.type)

    if (!isAllowedType) {
      message.error('Chỉ cho phép các định dạng JPEG, PNG, GIF, WEBP, BMP, JPG và MP4!')
      return Upload.LIST_IGNORE
    }

    const isImage = file.type.startsWith('image')
    const maxSize = isImage ? 10 : 35
    const isWithinLimit = file.size / 1024 / 1024 < maxSize

    if (!isWithinLimit) {
      message.error(isImage ? 'Dung lượng ảnh phải nhỏ hơn 10MB!' : 'Dung lượng video phải nhỏ hơn 35MB!')
      return Upload.LIST_IGNORE
    }

    setFileList((list) => [...list, file])
    return false
  }

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type='button'>
      <IoAdd />
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  )

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1)

  const renderPage = () => {
    switch (page) {
      case '1':
        return <Page1 />
      case '2':
        return <Page2 />
      case '3':
        return <Page3 />
      case '4':
        return <Page4 />
      case '5':
        return <Page5 />
      case '6':
        return <Page6 />
      case '7':
        return <Page7 />
      case '8':
        return <Page8 />
      case '9':
        return <Page9 />
      case '10':
        return <Page10 />
      default:
        return <Page1 />
    }
  }

  useEffect(() => {
    fetchMedicalRecord()
  }, [id])

  if (!data || loading) {
    return <p>Loading...</p>
  }

  return (
    <div className='form-medical'>
      <div className='flex justify-between'>
        <div className='flex justify-center space-x-4'>
          {pages.map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => goToPage(pageNumber.toString())}
              type={page === pageNumber.toString() ? 'primary' : 'default'}
              className='w-8'
            >
              {pageNumber}
            </Button>
          ))}
        </div>
        <div className='flex gap-3'>
          <Button
            onClick={() => {
              navigate(`/medical-records/${id}?page=${page}`)
            }}
          >
            Quay lại
          </Button>
          <Button type='primary' onClick={handleUpdate}>
            Cập nhật
          </Button>
        </div>
      </div>

      <div className='mt-4'>
        <Upload action='' listType='picture-card' fileList={fileList} onPreview={handlePreview} onChange={handleChange} beforeUpload={beforeUpload}>
          {fileList.length >= 5 ? null : uploadButton}
        </Upload>
        {previewImage && (
          <Image
            wrapperStyle={{ display: 'none' }}
            preview={{
              visible: previewOpen,
              onVisibleChange: (visible) => setPreviewOpen(visible),
              afterOpenChange: (visible) => !visible && setPreviewImage('')
            }}
            src={previewImage}
          />
        )}
      </div>

      {renderPage()}
    </div>
  )
}

export default EditLayout
