import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import Page4First from '@/modules/medical-record/assets/images/page4first.jpg'
import Page4Second from '@/modules/medical-record/assets/images/page4second.jpg'
import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { updateEditState } from '../../reducers/medicalReducer'

const Page4: React.FC = () => {
  const dispatch = useDispatch<any>()
  const dataState: MedicalFormState = useSelector((state: RootState) => state.medical.editState)
  const [data, setData] = useState<MedicalFormState>({
    ...dataState
  })

  const handleInputChange = (setter: any, field: any) => async (e: any) => {
    const { name, type, checked, value } = e.target

    const newValue = type === 'checkbox' ? checked : value

    await setter((prevState: any) => {
      if (field) {
        return {
          ...prevState,
          [field]: {
            ...prevState[field],
            [name]: newValue
          }
        }
      }
      return {
        ...prevState,
        [name]: newValue
      }
    })
  }

  useEffect(() => {
    dispatch(updateEditState(data))
  }, [data, dispatch])

  return (
    <div>
      <div className='mt-4 flex'>
        <span>Liên kết đến biểu đồ trực tuyến:</span>
        <input type='text' name='additionalLink' className='flex-1' value={data.additionalLink} onChange={handleInputChange(setData, null)} />
      </div>
      {/* table 1 */}
      <table className='mt-4 w-full border-collapse'>
        <tbody>
          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              {' '}
            </td>
            <td className='border border-black/50 px-2 py-1' colSpan={13}>
              Họ và tên: {data?.patient?.name}
            </td>
            <td className='border border-black/50 px-2 py-1' colSpan={3}>
              <span>Ngày:</span>
              <input type='text' name='ngay' className='w-20' value={data.p4table1.ngay} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>

          {/* Độ lung lay */}
          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              Độ lung lay
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay81' className='m-0 w-full' value={data.p4table1.lunglay81} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay71' className='m-0 w-full' value={data.p4table1.lunglay71} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay61' className='m-0 w-full' value={data.p4table1.lunglay61} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay51' className='m-0 w-full' value={data.p4table1.lunglay51} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay41' className='m-0 w-full' value={data.p4table1.lunglay41} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay31' className='m-0 w-full' value={data.p4table1.lunglay31} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay21' className='m-0 w-full' value={data.p4table1.lunglay21} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay11' className='m-0 w-full' value={data.p4table1.lunglay11} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay12' className='m-0 w-full' value={data.p4table1.lunglay12} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay22' className='m-0 w-full' value={data.p4table1.lunglay22} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay32' className='m-0 w-full' value={data.p4table1.lunglay32} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay42' className='m-0 w-full' value={data.p4table1.lunglay42} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay52' className='m-0 w-full' value={data.p4table1.lunglay52} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay62' className='m-0 w-full' value={data.p4table1.lunglay62} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay72' className='m-0 w-full' value={data.p4table1.lunglay72} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay82' className='m-0 w-full' value={data.p4table1.lunglay82} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>

          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              {' '}
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* PII */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii81' className='m-0 w-full' value={data.p4table1.pii81} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii71' className='m-0 w-full' value={data.p4table1.pii71} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii61' className='m-0 w-full' value={data.p4table1.pii61} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii51' className='m-0 w-full' value={data.p4table1.pii51} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii41' className='m-0 w-full' value={data.p4table1.pii41} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii31' className='m-0 w-full' value={data.p4table1.pii31} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii21' className='m-0 w-full' value={data.p4table1.pii21} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii11' className='m-0 w-full' value={data.p4table1.pii11} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii12' className='m-0 w-full' value={data.p4table1.pii12} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii22' className='m-0 w-full' value={data.p4table1.pii22} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii32' className='m-0 w-full' value={data.p4table1.pii32} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii42' className='m-0 w-full' value={data.p4table1.pii42} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii52' className='m-0 w-full' value={data.p4table1.pii52} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii62' className='m-0 w-full' value={data.p4table1.pii62} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii72' className='m-0 w-full' value={data.p4table1.pii72} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii82' className='m-0 w-full' value={data.p4table1.pii82} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>

          {/* GI */}
          <tr>
            <td className='px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi81' className='m-0 w-full' value={data.p4table1.gi81} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi71' className='m-0 w-full' value={data.p4table1.gi71} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi61' className='m-0 w-full' value={data.p4table1.gi61} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi51' className='m-0 w-full' value={data.p4table1.gi51} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi41' className='m-0 w-full' value={data.p4table1.gi41} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi31' className='m-0 w-full' value={data.p4table1.gi31} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi21' className='m-0 w-full' value={data.p4table1.gi21} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi11' className='m-0 w-full' value={data.p4table1.gi11} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi12' className='m-0 w-full' value={data.p4table1.gi12} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi22' className='m-0 w-full' value={data.p4table1.gi22} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi32' className='m-0 w-full' value={data.p4table1.gi32} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi42' className='m-0 w-full' value={data.p4table1.gi42} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi52' className='m-0 w-full' value={data.p4table1.gi52} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi62' className='m-0 w-full' value={data.p4table1.gi62} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi72' className='m-0 w-full' value={data.p4table1.gi72} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi82' className='m-0 w-full' value={data.p4table1.gi82} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd81' className='m-0 w-full' value={data.p4table1.ppd81} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd71' className='m-0 w-full' value={data.p4table1.ppd71} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd61' className='m-0 w-full' value={data.p4table1.ppd61} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd51' className='m-0 w-full' value={data.p4table1.ppd51} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd41' className='m-0 w-full' value={data.p4table1.ppd41} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd31' className='m-0 w-full' value={data.p4table1.ppd31} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd21' className='m-0 w-full' value={data.p4table1.ppd21} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd11' className='m-0 w-full' value={data.p4table1.ppd11} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd12' className='m-0 w-full' value={data.p4table1.ppd12} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd22' className='m-0 w-full' value={data.p4table1.ppd22} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd32' className='m-0 w-full' value={data.p4table1.ppd32} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd42' className='m-0 w-full' value={data.p4table1.ppd42} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd52' className='m-0 w-full' value={data.p4table1.ppd52} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd62' className='m-0 w-full' value={data.p4table1.ppd62} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd72' className='m-0 w-full' value={data.p4table1.ppd72} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd82' className='m-0 w-full' value={data.p4table1.ppd82} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>

          {/* GM */}
          <tr>
            <td className='px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm81' className='m-0 w-full' value={data.p4table1.gm81} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm71' className='m-0 w-full' value={data.p4table1.gm71} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm61' className='m-0 w-full' value={data.p4table1.gm61} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm51' className='m-0 w-full' value={data.p4table1.gm51} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm41' className='m-0 w-full' value={data.p4table1.gm41} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm31' className='m-0 w-full' value={data.p4table1.gm31} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm21' className='m-0 w-full' value={data.p4table1.gm21} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm11' className='m-0 w-full' value={data.p4table1.gm11} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm12' className='m-0 w-full' value={data.p4table1.gm12} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm22' className='m-0 w-full' value={data.p4table1.gm22} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm32' className='m-0 w-full' value={data.p4table1.gm32} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm42' className='m-0 w-full' value={data.p4table1.gm42} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm52' className='m-0 w-full' value={data.p4table1.gm52} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm62' className='m-0 w-full' value={data.p4table1.gm62} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm72' className='m-0 w-full' value={data.p4table1.gm72} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm82' className='m-0 w-full' value={data.p4table1.gm82} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal81' className='m-0 w-full' value={data.p4table1.cal81} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal71' className='m-0 w-full' value={data.p4table1.cal71} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal61' className='m-0 w-full' value={data.p4table1.cal61} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal51' className='m-0 w-full' value={data.p4table1.cal51} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal41' className='m-0 w-full' value={data.p4table1.cal41} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal31' className='m-0 w-full' value={data.p4table1.cal31} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal21' className='m-0 w-full' value={data.p4table1.cal21} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal11' className='m-0 w-full' value={data.p4table1.cal11} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal12' className='m-0 w-full' value={data.p4table1.cal12} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal22' className='m-0 w-full' value={data.p4table1.cal22} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal32' className='m-0 w-full' value={data.p4table1.cal32} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal42' className='m-0 w-full' value={data.p4table1.cal42} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal52' className='m-0 w-full' value={data.p4table1.cal52} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal62' className='m-0 w-full' value={data.p4table1.cal62} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal72' className='m-0 w-full' value={data.p4table1.cal72} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal82' className='m-0 w-full' value={data.p4table1.cal82} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop81' className='m-0 w-full' value={data.p4table1.bop81} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop71' className='m-0 w-full' value={data.p4table1.bop71} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop61' className='m-0 w-full' value={data.p4table1.bop61} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop51' className='m-0 w-full' value={data.p4table1.bop51} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop41' className='m-0 w-full' value={data.p4table1.bop41} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop31' className='m-0 w-full' value={data.p4table1.bop31} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop21' className='m-0 w-full' value={data.p4table1.bop21} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop11' className='m-0 w-full' value={data.p4table1.bop11} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop12' className='m-0 w-full' value={data.p4table1.bop12} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop22' className='m-0 w-full' value={data.p4table1.bop22} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop32' className='m-0 w-full' value={data.p4table1.bop32} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop42' className='m-0 w-full' value={data.p4table1.bop42} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop52' className='m-0 w-full' value={data.p4table1.bop52} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop62' className='m-0 w-full' value={data.p4table1.bop62} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop72' className='m-0 w-full' value={data.p4table1.bop72} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop82' className='m-0 w-full' value={data.p4table1.bop82} onChange={handleInputChange(setData, 'p4table1')} />
            </td>
          </tr>
        </tbody>
      </table>

      <div className='my-0 w-full py-0 ps-[110px]'>
        <img src={Page4First} alt='Rang' className='w-full' />
      </div>

      {/* Table 2 */}
      <table className='w-full border-collapse'>
        <tbody>
          {/* PII */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii81' className='m-0 w-full' value={data.p4table2.pii81} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii71' className='m-0 w-full' value={data.p4table2.pii71} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii61' className='m-0 w-full' value={data.p4table2.pii61} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii51' className='m-0 w-full' value={data.p4table2.pii51} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii41' className='m-0 w-full' value={data.p4table2.pii41} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii31' className='m-0 w-full' value={data.p4table2.pii31} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii21' className='m-0 w-full' value={data.p4table2.pii21} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii11' className='m-0 w-full' value={data.p4table2.pii11} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii12' className='m-0 w-full' value={data.p4table2.pii12} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii22' className='m-0 w-full' value={data.p4table2.pii22} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii32' className='m-0 w-full' value={data.p4table2.pii32} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii42' className='m-0 w-full' value={data.p4table2.pii42} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii52' className='m-0 w-full' value={data.p4table2.pii52} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii62' className='m-0 w-full' value={data.p4table2.pii62} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii72' className='m-0 w-full' value={data.p4table2.pii72} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii82' className='m-0 w-full' value={data.p4table2.pii82} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
          </tr>

          {/* GI */}
          <tr>
            <td className='px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi81' className='m-0 w-full' value={data.p4table2.gi81} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi71' className='m-0 w-full' value={data.p4table2.gi71} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi61' className='m-0 w-full' value={data.p4table2.gi61} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi51' className='m-0 w-full' value={data.p4table2.gi51} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi41' className='m-0 w-full' value={data.p4table2.gi41} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi31' className='m-0 w-full' value={data.p4table2.gi31} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi21' className='m-0 w-full' value={data.p4table2.gi21} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi11' className='m-0 w-full' value={data.p4table2.gi11} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi12' className='m-0 w-full' value={data.p4table2.gi12} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi22' className='m-0 w-full' value={data.p4table2.gi22} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi32' className='m-0 w-full' value={data.p4table2.gi32} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi42' className='m-0 w-full' value={data.p4table2.gi42} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi52' className='m-0 w-full' value={data.p4table2.gi52} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi62' className='m-0 w-full' value={data.p4table2.gi62} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi72' className='m-0 w-full' value={data.p4table2.gi72} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi82' className='m-0 w-full' value={data.p4table2.gi82} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd81' className='m-0 w-full' value={data.p4table2.ppd81} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd71' className='m-0 w-full' value={data.p4table2.ppd71} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd61' className='m-0 w-full' value={data.p4table2.ppd61} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd51' className='m-0 w-full' value={data.p4table2.ppd51} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd41' className='m-0 w-full' value={data.p4table2.ppd41} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd31' className='m-0 w-full' value={data.p4table2.ppd31} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd21' className='m-0 w-full' value={data.p4table2.ppd21} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd11' className='m-0 w-full' value={data.p4table2.ppd11} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd12' className='m-0 w-full' value={data.p4table2.ppd12} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd22' className='m-0 w-full' value={data.p4table2.ppd22} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd32' className='m-0 w-full' value={data.p4table2.ppd32} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd42' className='m-0 w-full' value={data.p4table2.ppd42} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd52' className='m-0 w-full' value={data.p4table2.ppd52} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd62' className='m-0 w-full' value={data.p4table2.ppd62} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd72' className='m-0 w-full' value={data.p4table2.ppd72} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd82' className='m-0 w-full' value={data.p4table2.ppd82} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
          </tr>

          {/* GM */}
          <tr>
            <td className='px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm81' className='m-0 w-full' value={data.p4table2.gm81} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm71' className='m-0 w-full' value={data.p4table2.gm71} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm61' className='m-0 w-full' value={data.p4table2.gm61} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm51' className='m-0 w-full' value={data.p4table2.gm51} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm41' className='m-0 w-full' value={data.p4table2.gm41} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm31' className='m-0 w-full' value={data.p4table2.gm31} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm21' className='m-0 w-full' value={data.p4table2.gm21} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm11' className='m-0 w-full' value={data.p4table2.gm11} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm12' className='m-0 w-full' value={data.p4table2.gm12} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm22' className='m-0 w-full' value={data.p4table2.gm22} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm32' className='m-0 w-full' value={data.p4table2.gm32} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm42' className='m-0 w-full' value={data.p4table2.gm42} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm52' className='m-0 w-full' value={data.p4table2.gm52} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm62' className='m-0 w-full' value={data.p4table2.gm62} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm72' className='m-0 w-full' value={data.p4table2.gm72} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm82' className='m-0 w-full' value={data.p4table2.gm82} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal81' className='m-0 w-full' value={data.p4table2.cal81} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal71' className='m-0 w-full' value={data.p4table2.cal71} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal61' className='m-0 w-full' value={data.p4table2.cal61} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal51' className='m-0 w-full' value={data.p4table2.cal51} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal41' className='m-0 w-full' value={data.p4table2.cal41} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal31' className='m-0 w-full' value={data.p4table2.cal31} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal21' className='m-0 w-full' value={data.p4table2.cal21} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal11' className='m-0 w-full' value={data.p4table2.cal11} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal12' className='m-0 w-full' value={data.p4table2.cal12} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal22' className='m-0 w-full' value={data.p4table2.cal22} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal32' className='m-0 w-full' value={data.p4table2.cal32} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal42' className='m-0 w-full' value={data.p4table2.cal42} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal52' className='m-0 w-full' value={data.p4table2.cal52} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal62' className='m-0 w-full' value={data.p4table2.cal62} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal72' className='m-0 w-full' value={data.p4table2.cal72} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal82' className='m-0 w-full' value={data.p4table2.cal82} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop81' className='m-0 w-full' value={data.p4table2.bop81} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop71' className='m-0 w-full' value={data.p4table2.bop71} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop61' className='m-0 w-full' value={data.p4table2.bop61} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop51' className='m-0 w-full' value={data.p4table2.bop51} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop41' className='m-0 w-full' value={data.p4table2.bop41} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop31' className='m-0 w-full' value={data.p4table2.bop31} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop21' className='m-0 w-full' value={data.p4table2.bop21} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop11' className='m-0 w-full' value={data.p4table2.bop11} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop12' className='m-0 w-full' value={data.p4table2.bop12} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop22' className='m-0 w-full' value={data.p4table2.bop22} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop32' className='m-0 w-full' value={data.p4table2.bop32} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop42' className='m-0 w-full' value={data.p4table2.bop42} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop52' className='m-0 w-full' value={data.p4table2.bop52} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop62' className='m-0 w-full' value={data.p4table2.bop62} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop72' className='m-0 w-full' value={data.p4table2.bop72} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop82' className='m-0 w-full' value={data.p4table2.bop82} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
          </tr>

          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              {' '}
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* Độ lung lay */}
          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              Độ lung lay
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay81' className='m-0 w-full' value={data.p4table2.lunglay81} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay71' className='m-0 w-full' value={data.p4table2.lunglay71} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay61' className='m-0 w-full' value={data.p4table2.lunglay61} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay51' className='m-0 w-full' value={data.p4table2.lunglay51} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay41' className='m-0 w-full' value={data.p4table2.lunglay41} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay31' className='m-0 w-full' value={data.p4table2.lunglay31} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay21' className='m-0 w-full' value={data.p4table2.lunglay21} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay11' className='m-0 w-full' value={data.p4table2.lunglay11} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay12' className='m-0 w-full' value={data.p4table2.lunglay12} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay22' className='m-0 w-full' value={data.p4table2.lunglay22} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay32' className='m-0 w-full' value={data.p4table2.lunglay32} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay42' className='m-0 w-full' value={data.p4table2.lunglay42} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay52' className='m-0 w-full' value={data.p4table2.lunglay52} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay62' className='m-0 w-full' value={data.p4table2.lunglay62} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay72' className='m-0 w-full' value={data.p4table2.lunglay72} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay82' className='m-0 w-full' value={data.p4table2.lunglay82} onChange={handleInputChange(setData, 'p4table2')} />
            </td>
          </tr>
        </tbody>
      </table>

      {/* Table 3 */}
      <table className='mt-10 w-full border-collapse'>
        <tbody>
          {/* Độ lung lay */}
          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              Độ lung lay
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay81' className='m-0 w-full' value={data.p4table3.lunglay81} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay71' className='m-0 w-full' value={data.p4table3.lunglay71} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay61' className='m-0 w-full' value={data.p4table3.lunglay61} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay51' className='m-0 w-full' value={data.p4table3.lunglay51} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay41' className='m-0 w-full' value={data.p4table3.lunglay41} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay31' className='m-0 w-full' value={data.p4table3.lunglay31} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay21' className='m-0 w-full' value={data.p4table3.lunglay21} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay11' className='m-0 w-full' value={data.p4table3.lunglay11} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay12' className='m-0 w-full' value={data.p4table3.lunglay12} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay22' className='m-0 w-full' value={data.p4table3.lunglay22} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay32' className='m-0 w-full' value={data.p4table3.lunglay32} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay42' className='m-0 w-full' value={data.p4table3.lunglay42} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay52' className='m-0 w-full' value={data.p4table3.lunglay52} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay62' className='m-0 w-full' value={data.p4table3.lunglay62} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay72' className='m-0 w-full' value={data.p4table3.lunglay72} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay82' className='m-0 w-full' value={data.p4table3.lunglay82} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
          </tr>

          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              {' '}
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* PII */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii81' className='m-0 w-full' value={data.p4table3.pii81} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii71' className='m-0 w-full' value={data.p4table3.pii71} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii61' className='m-0 w-full' value={data.p4table3.pii61} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii51' className='m-0 w-full' value={data.p4table3.pii51} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii41' className='m-0 w-full' value={data.p4table3.pii41} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii31' className='m-0 w-full' value={data.p4table3.pii31} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii21' className='m-0 w-full' value={data.p4table3.pii21} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii11' className='m-0 w-full' value={data.p4table3.pii11} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii12' className='m-0 w-full' value={data.p4table3.pii12} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii22' className='m-0 w-full' value={data.p4table3.pii22} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii32' className='m-0 w-full' value={data.p4table3.pii32} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii42' className='m-0 w-full' value={data.p4table3.pii42} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii52' className='m-0 w-full' value={data.p4table3.pii52} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii62' className='m-0 w-full' value={data.p4table3.pii62} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii72' className='m-0 w-full' value={data.p4table3.pii72} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii82' className='m-0 w-full' value={data.p4table3.pii82} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
          </tr>

          {/* GI */}
          <tr>
            <td className='px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi81' className='m-0 w-full' value={data.p4table3.gi81} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi71' className='m-0 w-full' value={data.p4table3.gi71} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi61' className='m-0 w-full' value={data.p4table3.gi61} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi51' className='m-0 w-full' value={data.p4table3.gi51} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi41' className='m-0 w-full' value={data.p4table3.gi41} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi31' className='m-0 w-full' value={data.p4table3.gi31} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi21' className='m-0 w-full' value={data.p4table3.gi21} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi11' className='m-0 w-full' value={data.p4table3.gi11} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi12' className='m-0 w-full' value={data.p4table3.gi12} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi22' className='m-0 w-full' value={data.p4table3.gi22} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi32' className='m-0 w-full' value={data.p4table3.gi32} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi42' className='m-0 w-full' value={data.p4table3.gi42} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi52' className='m-0 w-full' value={data.p4table3.gi52} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi62' className='m-0 w-full' value={data.p4table3.gi62} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi72' className='m-0 w-full' value={data.p4table3.gi72} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi82' className='m-0 w-full' value={data.p4table3.gi82} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd81' className='m-0 w-full' value={data.p4table3.ppd81} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd71' className='m-0 w-full' value={data.p4table3.ppd71} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd61' className='m-0 w-full' value={data.p4table3.ppd61} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd51' className='m-0 w-full' value={data.p4table3.ppd51} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd41' className='m-0 w-full' value={data.p4table3.ppd41} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd31' className='m-0 w-full' value={data.p4table3.ppd31} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd21' className='m-0 w-full' value={data.p4table3.ppd21} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd11' className='m-0 w-full' value={data.p4table3.ppd11} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd12' className='m-0 w-full' value={data.p4table3.ppd12} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd22' className='m-0 w-full' value={data.p4table3.ppd22} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd32' className='m-0 w-full' value={data.p4table3.ppd32} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd42' className='m-0 w-full' value={data.p4table3.ppd42} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd52' className='m-0 w-full' value={data.p4table3.ppd52} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd62' className='m-0 w-full' value={data.p4table3.ppd62} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd72' className='m-0 w-full' value={data.p4table3.ppd72} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd82' className='m-0 w-full' value={data.p4table3.ppd82} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
          </tr>

          {/* GM */}
          <tr>
            <td className='px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm81' className='m-0 w-full' value={data.p4table3.gm81} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm71' className='m-0 w-full' value={data.p4table3.gm71} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm61' className='m-0 w-full' value={data.p4table3.gm61} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm51' className='m-0 w-full' value={data.p4table3.gm51} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm41' className='m-0 w-full' value={data.p4table3.gm41} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm31' className='m-0 w-full' value={data.p4table3.gm31} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm21' className='m-0 w-full' value={data.p4table3.gm21} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm11' className='m-0 w-full' value={data.p4table3.gm11} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm12' className='m-0 w-full' value={data.p4table3.gm12} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm22' className='m-0 w-full' value={data.p4table3.gm22} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm32' className='m-0 w-full' value={data.p4table3.gm32} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm42' className='m-0 w-full' value={data.p4table3.gm42} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm52' className='m-0 w-full' value={data.p4table3.gm52} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm62' className='m-0 w-full' value={data.p4table3.gm62} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm72' className='m-0 w-full' value={data.p4table3.gm72} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm82' className='m-0 w-full' value={data.p4table3.gm82} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal81' className='m-0 w-full' value={data.p4table3.cal81} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal71' className='m-0 w-full' value={data.p4table3.cal71} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal61' className='m-0 w-full' value={data.p4table3.cal61} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal51' className='m-0 w-full' value={data.p4table3.cal51} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal41' className='m-0 w-full' value={data.p4table3.cal41} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal31' className='m-0 w-full' value={data.p4table3.cal31} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal21' className='m-0 w-full' value={data.p4table3.cal21} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal11' className='m-0 w-full' value={data.p4table3.cal11} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal12' className='m-0 w-full' value={data.p4table3.cal12} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal22' className='m-0 w-full' value={data.p4table3.cal22} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal32' className='m-0 w-full' value={data.p4table3.cal32} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal42' className='m-0 w-full' value={data.p4table3.cal42} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal52' className='m-0 w-full' value={data.p4table3.cal52} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal62' className='m-0 w-full' value={data.p4table3.cal62} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal72' className='m-0 w-full' value={data.p4table3.cal72} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal82' className='m-0 w-full' value={data.p4table3.cal82} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop81' className='m-0 w-full' value={data.p4table3.bop81} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop71' className='m-0 w-full' value={data.p4table3.bop71} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop61' className='m-0 w-full' value={data.p4table3.bop61} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop51' className='m-0 w-full' value={data.p4table3.bop51} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop41' className='m-0 w-full' value={data.p4table3.bop41} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop31' className='m-0 w-full' value={data.p4table3.bop31} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop21' className='m-0 w-full' value={data.p4table3.bop21} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop11' className='m-0 w-full' value={data.p4table3.bop11} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop12' className='m-0 w-full' value={data.p4table3.bop12} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop22' className='m-0 w-full' value={data.p4table3.bop22} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop32' className='m-0 w-full' value={data.p4table3.bop32} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop42' className='m-0 w-full' value={data.p4table3.bop42} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop52' className='m-0 w-full' value={data.p4table3.bop52} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop62' className='m-0 w-full' value={data.p4table3.bop62} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop72' className='m-0 w-full' value={data.p4table3.bop72} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop82' className='m-0 w-full' value={data.p4table3.bop82} onChange={handleInputChange(setData, 'p4table3')} />
            </td>
          </tr>
        </tbody>
      </table>

      <div className='my-0 w-full py-0 ps-[110px]'>
        <img src={Page4Second} alt='Rang' className='w-full' />
      </div>

      {/* Table 4 */}
      <table className='w-full border-collapse'>
        <tbody>
          {/* PII */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>PII</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii81' className='m-0 w-full' value={data.p4table4.pii81} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii71' className='m-0 w-full' value={data.p4table4.pii71} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii61' className='m-0 w-full' value={data.p4table4.pii61} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii51' className='m-0 w-full' value={data.p4table4.pii51} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii41' className='m-0 w-full' value={data.p4table4.pii41} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii31' className='m-0 w-full' value={data.p4table4.pii31} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii21' className='m-0 w-full' value={data.p4table4.pii21} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii11' className='m-0 w-full' value={data.p4table4.pii11} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='pii12' className='m-0 w-full' value={data.p4table4.pii12} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii22' className='m-0 w-full' value={data.p4table4.pii22} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii32' className='m-0 w-full' value={data.p4table4.pii32} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii42' className='m-0 w-full' value={data.p4table4.pii42} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii52' className='m-0 w-full' value={data.p4table4.pii52} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii62' className='m-0 w-full' value={data.p4table4.pii62} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii72' className='m-0 w-full' value={data.p4table4.pii72} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='pii82' className='m-0 w-full' value={data.p4table4.pii82} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
          </tr>

          {/* GI */}
          <tr>
            <td className='px-2 py-1 text-end'>GI</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi81' className='m-0 w-full' value={data.p4table4.gi81} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi71' className='m-0 w-full' value={data.p4table4.gi71} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi61' className='m-0 w-full' value={data.p4table4.gi61} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi51' className='m-0 w-full' value={data.p4table4.gi51} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi41' className='m-0 w-full' value={data.p4table4.gi41} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi31' className='m-0 w-full' value={data.p4table4.gi31} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi21' className='m-0 w-full' value={data.p4table4.gi21} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi11' className='m-0 w-full' value={data.p4table4.gi11} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gi12' className='m-0 w-full' value={data.p4table4.gi12} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi22' className='m-0 w-full' value={data.p4table4.gi22} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi32' className='m-0 w-full' value={data.p4table4.gi32} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi42' className='m-0 w-full' value={data.p4table4.gi42} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi52' className='m-0 w-full' value={data.p4table4.gi52} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi62' className='m-0 w-full' value={data.p4table4.gi62} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi72' className='m-0 w-full' value={data.p4table4.gi72} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gi82' className='m-0 w-full' value={data.p4table4.gi82} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
          </tr>

          {/* PPD */}
          <tr>
            <td className='px-2 py-1 text-end'>PPD</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd81' className='m-0 w-full' value={data.p4table4.ppd81} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd71' className='m-0 w-full' value={data.p4table4.ppd71} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd61' className='m-0 w-full' value={data.p4table4.ppd61} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd51' className='m-0 w-full' value={data.p4table4.ppd51} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd41' className='m-0 w-full' value={data.p4table4.ppd41} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd31' className='m-0 w-full' value={data.p4table4.ppd31} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd21' className='m-0 w-full' value={data.p4table4.ppd21} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd11' className='m-0 w-full' value={data.p4table4.ppd11} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='ppd12' className='m-0 w-full' value={data.p4table4.ppd12} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd22' className='m-0 w-full' value={data.p4table4.ppd22} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd32' className='m-0 w-full' value={data.p4table4.ppd32} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd42' className='m-0 w-full' value={data.p4table4.ppd42} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd52' className='m-0 w-full' value={data.p4table4.ppd52} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd62' className='m-0 w-full' value={data.p4table4.ppd62} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd72' className='m-0 w-full' value={data.p4table4.ppd72} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='ppd82' className='m-0 w-full' value={data.p4table4.ppd82} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
          </tr>

          {/* GM */}
          <tr>
            <td className='px-2 py-1 text-end'>GM</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm81' className='m-0 w-full' value={data.p4table4.gm81} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm71' className='m-0 w-full' value={data.p4table4.gm71} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm61' className='m-0 w-full' value={data.p4table4.gm61} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm51' className='m-0 w-full' value={data.p4table4.gm51} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm41' className='m-0 w-full' value={data.p4table4.gm41} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm31' className='m-0 w-full' value={data.p4table4.gm31} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm21' className='m-0 w-full' value={data.p4table4.gm21} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm11' className='m-0 w-full' value={data.p4table4.gm11} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='gm12' className='m-0 w-full' value={data.p4table4.gm12} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm22' className='m-0 w-full' value={data.p4table4.gm22} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm32' className='m-0 w-full' value={data.p4table4.gm32} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm42' className='m-0 w-full' value={data.p4table4.gm42} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm52' className='m-0 w-full' value={data.p4table4.gm52} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm62' className='m-0 w-full' value={data.p4table4.gm62} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm72' className='m-0 w-full' value={data.p4table4.gm72} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='gm82' className='m-0 w-full' value={data.p4table4.gm82} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
          </tr>

          {/* CAL */}
          <tr>
            <td className='px-2 py-1 text-end'>CAL</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal81' className='m-0 w-full' value={data.p4table4.cal81} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal71' className='m-0 w-full' value={data.p4table4.cal71} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal61' className='m-0 w-full' value={data.p4table4.cal61} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal51' className='m-0 w-full' value={data.p4table4.cal51} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal41' className='m-0 w-full' value={data.p4table4.cal41} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal31' className='m-0 w-full' value={data.p4table4.cal31} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal21' className='m-0 w-full' value={data.p4table4.cal21} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal11' className='m-0 w-full' value={data.p4table4.cal11} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='cal12' className='m-0 w-full' value={data.p4table4.cal12} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal22' className='m-0 w-full' value={data.p4table4.cal22} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal32' className='m-0 w-full' value={data.p4table4.cal32} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal42' className='m-0 w-full' value={data.p4table4.cal42} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal52' className='m-0 w-full' value={data.p4table4.cal52} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal62' className='m-0 w-full' value={data.p4table4.cal62} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal72' className='m-0 w-full' value={data.p4table4.cal72} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='cal82' className='m-0 w-full' value={data.p4table4.cal82} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
          </tr>

          {/* BOP */}
          <tr>
            <td className='px-2 py-1 text-end font-bold'>BOP</td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop81' className='m-0 w-full' value={data.p4table4.bop81} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop71' className='m-0 w-full' value={data.p4table4.bop71} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop61' className='m-0 w-full' value={data.p4table4.bop61} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop51' className='m-0 w-full' value={data.p4table4.bop51} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop41' className='m-0 w-full' value={data.p4table4.bop41} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop31' className='m-0 w-full' value={data.p4table4.bop31} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop21' className='m-0 w-full' value={data.p4table4.bop21} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop11' className='m-0 w-full' value={data.p4table4.bop11} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='bop12' className='m-0 w-full' value={data.p4table4.bop12} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop22' className='m-0 w-full' value={data.p4table4.bop22} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop32' className='m-0 w-full' value={data.p4table4.bop32} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop42' className='m-0 w-full' value={data.p4table4.bop42} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop52' className='m-0 w-full' value={data.p4table4.bop52} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop62' className='m-0 w-full' value={data.p4table4.bop62} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop72' className='m-0 w-full' value={data.p4table4.bop72} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='bop82' className='m-0 w-full' value={data.p4table4.bop82} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
          </tr>

          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              {' '}
            </td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1 text-center'>1</td>
            <td className='border border-black/50 px-2 py-1 text-center'>2</td>
            <td className='border border-black/50 px-2 py-1 text-center'>3</td>
            <td className='border border-black/50 px-2 py-1 text-center'>4</td>
            <td className='border border-black/50 px-2 py-1 text-center'>5</td>
            <td className='border border-black/50 px-2 py-1 text-center'>6</td>
            <td className='border border-black/50 px-2 py-1 text-center'>7</td>
            <td className='border border-black/50 px-2 py-1 text-center'>8</td>
          </tr>

          {/* Độ lung lay */}
          <tr>
            <td className='px-2 py-1 text-end' width={120}>
              Độ lung lay
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay81' className='m-0 w-full' value={data.p4table4.lunglay81} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay71' className='m-0 w-full' value={data.p4table4.lunglay71} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay61' className='m-0 w-full' value={data.p4table4.lunglay61} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay51' className='m-0 w-full' value={data.p4table4.lunglay51} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay41' className='m-0 w-full' value={data.p4table4.lunglay41} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay31' className='m-0 w-full' value={data.p4table4.lunglay31} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay21' className='m-0 w-full' value={data.p4table4.lunglay21} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-r-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay11' className='m-0 w-full' value={data.p4table4.lunglay11} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-l-[3px] border-black/50 px-2 py-1'>
              <input type='text' name='lunglay12' className='m-0 w-full' value={data.p4table4.lunglay12} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay22' className='m-0 w-full' value={data.p4table4.lunglay22} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay32' className='m-0 w-full' value={data.p4table4.lunglay32} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay42' className='m-0 w-full' value={data.p4table4.lunglay42} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay52' className='m-0 w-full' value={data.p4table4.lunglay52} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay62' className='m-0 w-full' value={data.p4table4.lunglay62} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay72' className='m-0 w-full' value={data.p4table4.lunglay72} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
            <td className='border border-black/50 px-2 py-1'>
              <input type='text' name='lunglay82' className='m-0 w-full' value={data.p4table4.lunglay82} onChange={handleInputChange(setData, 'p4table4')} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default Page4
