import React from 'react'

import DepartmentList from './DepartmentList'
import Organization from './Organization'

export const OrganizationRoutes = [
  {
    path: 'organization',
    element: <Organization />
  },
  {
    path: 'organization/departments',
    element: <DepartmentList />
  }
]
