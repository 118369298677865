import { genCode } from './helper'

export interface MedicalFormState {
  // page 1
  code: string
  student: string
  patient: {
    name: string
    dob: string
    job: string
    education: string
    gender: string
    nation: string
    contact: string
    address: string
    contactPerson: string
    firstExam: string
    reason: string
    history: string
  }
  weight: string
  height: string
  sick: {
    diung: boolean
    bamsinh: boolean
    hutthuoc: boolean
    ruou: boolean
    maydieuhoa: boolean
    stentmachmau: boolean
    mangthai: boolean
    choconbu: boolean
    bingatxiu: boolean
    viemkhop: boolean
    viemgan: boolean
    lao: boolean
    caohuyetap: boolean
    daithaoduong: boolean
    benhtim: boolean
    benhmau: boolean
    benhthan: boolean
    benhsuyen: boolean
  }
  tamly: {
    khotiepcan: boolean
    khohoptac: boolean
    yeucauthammy: boolean
    tulamhai: boolean
    loausohai: boolean
    vandetaichinh: boolean
  }
  dental: {
    reasonHistory: string
    dungtam: boolean
    dungchi: boolean
    dungbanchai: boolean
    chaihangngay: boolean
    khamdinhky: boolean
    cachchairang: string
    anthucphamcung: boolean
    anthucphamchua: boolean
    anthucphamngot: boolean
    uongnuocgacoga: boolean
  }
  thoiquenrang: {
    nhaimotben: boolean
    canmoi: boolean
    nghienrangkhingu: boolean
    nhaivatcung: boolean
    tatdayluoi: boolean
    nghienchatrang: boolean
  }

  khamngoaimat: {
    mota: string
  }

  khamtrongmieng: {
    mota: string
  }

  // page 2
  ngoaimat: string
  trongmieng: string
  cankhop: {
    anglephai: string
    angletrai: string
    canphu: string
    canchia: string
    biendo: string
    dolech: string
    tinhchat: string
    row1col1: string
    row1col2: string
    row1col3: string
    row2col2: string
    row2col3: string
    row3col2: string
    row3col3: string
  }
  congspee: string
  congwilson: string
  cankhopkhac: string

  // page 3
  tiepxucrang: {
    row1col2: string
    row1col3: string
    row2col2: string
    row2col3: string
    row3col2: string
    row3col3: string
  }
  rang11: string
  rang12: string
  rang13: string
  rang14: string
  rang15: string
  rang16: string
  rang17: string
  rang18: string
  rang21: string
  rang22: string
  rang23: string
  rang24: string
  rang25: string
  rang26: string
  rang27: string
  rang28: string
  rang31: string
  rang32: string
  rang33: string
  rang34: string
  rang35: string
  rang36: string
  rang37: string
  rang38: string
  rang41: string
  rang42: string
  rang43: string
  rang44: string
  rang45: string
  rang46: string
  rang47: string
  rang48: string
  canlamsang: {
    phimquanhkhop: boolean
    mauham: boolean
    toancanh: boolean
    mau: boolean
    cancanh: boolean
    conebeam: boolean
    khac: string
    nhanxet: string
  }

  // page 4
  additionalLink: string
  p4table1: {
    ngay: string

    // lung lay
    lunglay81: string
    lunglay71: string
    lunglay61: string
    lunglay51: string
    lunglay41: string
    lunglay31: string
    lunglay21: string
    lunglay11: string
    lunglay12: string
    lunglay22: string
    lunglay32: string
    lunglay42: string
    lunglay52: string
    lunglay62: string
    lunglay72: string
    lunglay82: string

    // PII
    pii81: string
    pii71: string
    pii61: string
    pii51: string
    pii41: string
    pii31: string
    pii21: string
    pii11: string
    pii12: string
    pii22: string
    pii32: string
    pii42: string
    pii52: string
    pii62: string
    pii72: string
    pii82: string

    // GI
    gi81: string
    gi71: string
    gi61: string
    gi51: string
    gi41: string
    gi31: string
    gi21: string
    gi11: string
    gi12: string
    gi22: string
    gi32: string
    gi42: string
    gi52: string
    gi62: string
    gi72: string
    gi82: string

    // PPD
    ppd81: string
    ppd71: string
    ppd61: string
    ppd51: string
    ppd41: string
    ppd31: string
    ppd21: string
    ppd11: string
    ppd12: string
    ppd22: string
    ppd32: string
    ppd42: string
    ppd52: string
    ppd62: string
    ppd72: string
    ppd82: string

    // GM
    gm81: string
    gm71: string
    gm61: string
    gm51: string
    gm41: string
    gm31: string
    gm21: string
    gm11: string
    gm12: string
    gm22: string
    gm32: string
    gm42: string
    gm52: string
    gm62: string
    gm72: string
    gm82: string

    // CAL
    cal81: string
    cal71: string
    cal61: string
    cal51: string
    cal41: string
    cal31: string
    cal21: string
    cal11: string
    cal12: string
    cal22: string
    cal32: string
    cal42: string
    cal52: string
    cal62: string
    cal72: string
    cal82: string

    // BOP
    bop81: string
    bop71: string
    bop61: string
    bop51: string
    bop41: string
    bop31: string
    bop21: string
    bop11: string
    bop12: string
    bop22: string
    bop32: string
    bop42: string
    bop52: string
    bop62: string
    bop72: string
    bop82: string
  }

  p4table2: {
    // lung lay
    lunglay81: string
    lunglay71: string
    lunglay61: string
    lunglay51: string
    lunglay41: string
    lunglay31: string
    lunglay21: string
    lunglay11: string
    lunglay12: string
    lunglay22: string
    lunglay32: string
    lunglay42: string
    lunglay52: string
    lunglay62: string
    lunglay72: string
    lunglay82: string

    // PII
    pii81: string
    pii71: string
    pii61: string
    pii51: string
    pii41: string
    pii31: string
    pii21: string
    pii11: string
    pii12: string
    pii22: string
    pii32: string
    pii42: string
    pii52: string
    pii62: string
    pii72: string
    pii82: string

    // GI
    gi81: string
    gi71: string
    gi61: string
    gi51: string
    gi41: string
    gi31: string
    gi21: string
    gi11: string
    gi12: string
    gi22: string
    gi32: string
    gi42: string
    gi52: string
    gi62: string
    gi72: string
    gi82: string

    // PPD
    ppd81: string
    ppd71: string
    ppd61: string
    ppd51: string
    ppd41: string
    ppd31: string
    ppd21: string
    ppd11: string
    ppd12: string
    ppd22: string
    ppd32: string
    ppd42: string
    ppd52: string
    ppd62: string
    ppd72: string
    ppd82: string

    // GM
    gm81: string
    gm71: string
    gm61: string
    gm51: string
    gm41: string
    gm31: string
    gm21: string
    gm11: string
    gm12: string
    gm22: string
    gm32: string
    gm42: string
    gm52: string
    gm62: string
    gm72: string
    gm82: string

    // CAL
    cal81: string
    cal71: string
    cal61: string
    cal51: string
    cal41: string
    cal31: string
    cal21: string
    cal11: string
    cal12: string
    cal22: string
    cal32: string
    cal42: string
    cal52: string
    cal62: string
    cal72: string
    cal82: string

    // BOP
    bop81: string
    bop71: string
    bop61: string
    bop51: string
    bop41: string
    bop31: string
    bop21: string
    bop11: string
    bop12: string
    bop22: string
    bop32: string
    bop42: string
    bop52: string
    bop62: string
    bop72: string
    bop82: string
  }

  p4table3: {
    // lung lay
    lunglay81: string
    lunglay71: string
    lunglay61: string
    lunglay51: string
    lunglay41: string
    lunglay31: string
    lunglay21: string
    lunglay11: string
    lunglay12: string
    lunglay22: string
    lunglay32: string
    lunglay42: string
    lunglay52: string
    lunglay62: string
    lunglay72: string
    lunglay82: string

    // PII
    pii81: string
    pii71: string
    pii61: string
    pii51: string
    pii41: string
    pii31: string
    pii21: string
    pii11: string
    pii12: string
    pii22: string
    pii32: string
    pii42: string
    pii52: string
    pii62: string
    pii72: string
    pii82: string

    // GI
    gi81: string
    gi71: string
    gi61: string
    gi51: string
    gi41: string
    gi31: string
    gi21: string
    gi11: string
    gi12: string
    gi22: string
    gi32: string
    gi42: string
    gi52: string
    gi62: string
    gi72: string
    gi82: string

    // PPD
    ppd81: string
    ppd71: string
    ppd61: string
    ppd51: string
    ppd41: string
    ppd31: string
    ppd21: string
    ppd11: string
    ppd12: string
    ppd22: string
    ppd32: string
    ppd42: string
    ppd52: string
    ppd62: string
    ppd72: string
    ppd82: string

    // GM
    gm81: string
    gm71: string
    gm61: string
    gm51: string
    gm41: string
    gm31: string
    gm21: string
    gm11: string
    gm12: string
    gm22: string
    gm32: string
    gm42: string
    gm52: string
    gm62: string
    gm72: string
    gm82: string

    // CAL
    cal81: string
    cal71: string
    cal61: string
    cal51: string
    cal41: string
    cal31: string
    cal21: string
    cal11: string
    cal12: string
    cal22: string
    cal32: string
    cal42: string
    cal52: string
    cal62: string
    cal72: string
    cal82: string

    // BOP
    bop81: string
    bop71: string
    bop61: string
    bop51: string
    bop41: string
    bop31: string
    bop21: string
    bop11: string
    bop12: string
    bop22: string
    bop32: string
    bop42: string
    bop52: string
    bop62: string
    bop72: string
    bop82: string
  }

  p4table4: {
    // lung lay
    lunglay81: string
    lunglay71: string
    lunglay61: string
    lunglay51: string
    lunglay41: string
    lunglay31: string
    lunglay21: string
    lunglay11: string
    lunglay12: string
    lunglay22: string
    lunglay32: string
    lunglay42: string
    lunglay52: string
    lunglay62: string
    lunglay72: string
    lunglay82: string

    // PII
    pii81: string
    pii71: string
    pii61: string
    pii51: string
    pii41: string
    pii31: string
    pii21: string
    pii11: string
    pii12: string
    pii22: string
    pii32: string
    pii42: string
    pii52: string
    pii62: string
    pii72: string
    pii82: string

    // GI
    gi81: string
    gi71: string
    gi61: string
    gi51: string
    gi41: string
    gi31: string
    gi21: string
    gi11: string
    gi12: string
    gi22: string
    gi32: string
    gi42: string
    gi52: string
    gi62: string
    gi72: string
    gi82: string

    // PPD
    ppd81: string
    ppd71: string
    ppd61: string
    ppd51: string
    ppd41: string
    ppd31: string
    ppd21: string
    ppd11: string
    ppd12: string
    ppd22: string
    ppd32: string
    ppd42: string
    ppd52: string
    ppd62: string
    ppd72: string
    ppd82: string

    // GM
    gm81: string
    gm71: string
    gm61: string
    gm51: string
    gm41: string
    gm31: string
    gm21: string
    gm11: string
    gm12: string
    gm22: string
    gm32: string
    gm42: string
    gm52: string
    gm62: string
    gm72: string
    gm82: string

    // CAL
    cal81: string
    cal71: string
    cal61: string
    cal51: string
    cal41: string
    cal31: string
    cal21: string
    cal11: string
    cal12: string
    cal22: string
    cal32: string
    cal42: string
    cal52: string
    cal62: string
    cal72: string
    cal82: string

    // BOP
    bop81: string
    bop71: string
    bop61: string
    bop51: string
    bop41: string
    bop31: string
    bop21: string
    bop11: string
    bop12: string
    bop22: string
    bop32: string
    bop42: string
    bop52: string
    bop62: string
    bop72: string
    bop82: string
  }

  // page 5
  tomtat: string
  chandoan1: string
  chandoan2: string
  chandoan3: string
  chandoan4: string
  chandoan5: string

  // page 6
  row1: {
    luuy: string
    xutri: string
  }
  row2: {
    luuy: string
    xutri: string
  }
  row3: {
    luuy: string
    xutri: string
  }
  row4: {
    loai: string
    gia: string
    sobuoi: string
  }
  row5: {
    loai: string
    gia: string
    sobuoi: string
  }
  row6: {
    loai: string
    gia: string
    sobuoi: string
  }
  row7: {
    loai: string
    gia: string
    sobuoi: string
  }
  row8: {
    loai: string
    gia: string
    sobuoi: string
  }
  row9: {
    loai: string
    gia: string
    sobuoi: string
  }
  row10: {
    loai: string
    gia: string
    sobuoi: string
  }
  row11: {
    loai: string
    gia: string
    sobuoi: string
  }
  row12: {
    loai: string
    gia: string
    sobuoi: string
  }
  row13: {
    loai: string
    gia: string
    sobuoi: string
  }
  row14: {
    loai: string
    gia: string
    sobuoi: string
  }
  row15: {
    loai: string
    gia: string
    sobuoi: string
  }
  row16: {
    loai: string
    gia: string
    sobuoi: string
  }
  row17: {
    loai: string
    gia: string
    sobuoi: string
  }
  row18: {
    loai: string
    gia: string
    sobuoi: string
  }
  row19: {
    loai: string
    gia: string
    sobuoi: string
  }
  rowtotal: {
    gia: string
    sobuoi: string
  }

  // page 7
  ques1: number
  ques2: number
  ques3: string
  ques4: string // yes, no
  ques5: number
  ques6: string
  ques7: string
  datepage7first: string

  ques21: number
  ques22: number
  ques23: number
  ques24: number
  ques25: string // yes, no
  ques26: number
  benhnhangopy: string
  datepage7second: string

  // page 8
  p8row1: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row2: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row3: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row4: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row5: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row6: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row7: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row8: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row9: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row10: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row11: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row12: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row13: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row14: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }
  p8row15: {
    rang: string
    noidung: string
    svdieutri: string
    svtrothu: string
    mentor: string
    note: string
  }

  // page 9
  page9: {
    row1col1: string
    row1col2: string
    row1col3: string
    row1col4: string
    row2col1: string
    row2col2: string
    row2col3: string
    row2col4: string

    note1: string
    note2: string
    note3: string
    note4: string
  }

  // page 10
  giaotiep: {
    row1: string | number
    row2: string | number
    row3: string | number
    row4: string | number
    row5: string | number
    row6: string | number
    note: string
  }

  tontrong: {
    row1: string | number
    row2: string | number
    row3: string | number
    row4: string | number
    note: string
  }

  daoduc: {
    row1: string | number
    row2: string | number
    row3: string | number
    row4: string | number
    row5: string | number
    note: string
  }

  thaido: {
    row1: string | number
    row2: string | number
    row3: string | number
    row4: string | number
    row5: string | number
    row6: string | number
    note: string
  }

  chuanbi: {
    row1: string | number
    row2: string | number
    row3: string | number
    row4: string | number
    row5: string | number
    note: string
  }
}

export const initialMedicalFormState: MedicalFormState = {
  // page 1
  code: genCode(),
  student: '',
  patient: {
    name: '',
    dob: '',
    job: '',
    education: '',
    gender: '',
    nation: '',
    contact: '',
    address: '',
    contactPerson: '',
    firstExam: '',
    reason: '',
    history: 'Không'
  },
  weight: '',
  height: '',
  sick: {
    diung: false,
    bamsinh: false,
    hutthuoc: false,
    ruou: false,
    maydieuhoa: false,
    stentmachmau: false,
    mangthai: false,
    choconbu: false,
    bingatxiu: false,
    viemkhop: false,
    viemgan: false,
    lao: false,
    caohuyetap: false,
    daithaoduong: false,
    benhtim: false,
    benhmau: false,
    benhthan: false,
    benhsuyen: false
  },
  tamly: {
    khotiepcan: false,
    khohoptac: false,
    yeucauthammy: false,
    tulamhai: false,
    loausohai: false,
    vandetaichinh: false
  },
  dental: {
    reasonHistory: '',
    dungtam: false,
    dungchi: false,
    dungbanchai: false,
    chaihangngay: false,
    khamdinhky: false,
    cachchairang: '',
    anthucphamcung: false,
    anthucphamchua: false,
    anthucphamngot: false,
    uongnuocgacoga: false
  },
  thoiquenrang: {
    nhaimotben: false,
    canmoi: false,
    nghienrangkhingu: false,
    nhaivatcung: false,
    tatdayluoi: false,
    nghienchatrang: false
  },

  khamngoaimat: {
    mota: ''
  },

  khamtrongmieng: {
    mota: ''
  },

  // page 2
  ngoaimat: '',
  trongmieng: '',
  cankhop: {
    anglephai: '',
    angletrai: '',
    canphu: '',
    canchia: '',
    biendo: '',
    dolech: '',
    tinhchat: '',
    row1col1: '',
    row1col2: '',
    row1col3: '',
    row2col2: '',
    row2col3: '',
    row3col2: '',
    row3col3: ''
  },
  congspee: '',
  congwilson: '',
  cankhopkhac: '',

  // page 3
  tiepxucrang: {
    row1col2: '',
    row1col3: '',
    row2col2: '',
    row2col3: '',
    row3col2: '',
    row3col3: ''
  },
  rang11: '',
  rang12: '',
  rang13: '',
  rang14: '',
  rang15: '',
  rang16: '',
  rang17: '',
  rang18: '',
  rang21: '',
  rang22: '',
  rang23: '',
  rang24: '',
  rang25: '',
  rang26: '',
  rang27: '',
  rang28: '',
  rang31: '',
  rang32: '',
  rang33: '',
  rang34: '',
  rang35: '',
  rang36: '',
  rang37: '',
  rang38: '',
  rang41: '',
  rang42: '',
  rang43: '',
  rang44: '',
  rang45: '',
  rang46: '',
  rang47: '',
  rang48: '',
  canlamsang: {
    phimquanhkhop: false,
    mauham: false,
    toancanh: false,
    mau: false,
    cancanh: false,
    conebeam: false,
    khac: '',
    nhanxet: ''
  },

  // page 4
  additionalLink: '',
  p4table1: {
    ngay: '',

    // lung lay
    lunglay81: '',
    lunglay71: '',
    lunglay61: '',
    lunglay51: '',
    lunglay41: '',
    lunglay31: '',
    lunglay21: '',
    lunglay11: '',
    lunglay12: '',
    lunglay22: '',
    lunglay32: '',
    lunglay42: '',
    lunglay52: '',
    lunglay62: '',
    lunglay72: '',
    lunglay82: '',

    // PII
    pii81: '',
    pii71: '',
    pii61: '',
    pii51: '',
    pii41: '',
    pii31: '',
    pii21: '',
    pii11: '',
    pii12: '',
    pii22: '',
    pii32: '',
    pii42: '',
    pii52: '',
    pii62: '',
    pii72: '',
    pii82: '',

    // GI
    gi81: '',
    gi71: '',
    gi61: '',
    gi51: '',
    gi41: '',
    gi31: '',
    gi21: '',
    gi11: '',
    gi12: '',
    gi22: '',
    gi32: '',
    gi42: '',
    gi52: '',
    gi62: '',
    gi72: '',
    gi82: '',

    // PPD
    ppd81: '',
    ppd71: '',
    ppd61: '',
    ppd51: '',
    ppd41: '',
    ppd31: '',
    ppd21: '',
    ppd11: '',
    ppd12: '',
    ppd22: '',
    ppd32: '',
    ppd42: '',
    ppd52: '',
    ppd62: '',
    ppd72: '',
    ppd82: '',

    // GM
    gm81: '',
    gm71: '',
    gm61: '',
    gm51: '',
    gm41: '',
    gm31: '',
    gm21: '',
    gm11: '',
    gm12: '',
    gm22: '',
    gm32: '',
    gm42: '',
    gm52: '',
    gm62: '',
    gm72: '',
    gm82: '',

    // CAL
    cal81: '',
    cal71: '',
    cal61: '',
    cal51: '',
    cal41: '',
    cal31: '',
    cal21: '',
    cal11: '',
    cal12: '',
    cal22: '',
    cal32: '',
    cal42: '',
    cal52: '',
    cal62: '',
    cal72: '',
    cal82: '',

    // BOP
    bop81: '',
    bop71: '',
    bop61: '',
    bop51: '',
    bop41: '',
    bop31: '',
    bop21: '',
    bop11: '',
    bop12: '',
    bop22: '',
    bop32: '',
    bop42: '',
    bop52: '',
    bop62: '',
    bop72: '',
    bop82: ''
  },

  p4table2: {
    // lung lay
    lunglay81: '',
    lunglay71: '',
    lunglay61: '',
    lunglay51: '',
    lunglay41: '',
    lunglay31: '',
    lunglay21: '',
    lunglay11: '',
    lunglay12: '',
    lunglay22: '',
    lunglay32: '',
    lunglay42: '',
    lunglay52: '',
    lunglay62: '',
    lunglay72: '',
    lunglay82: '',

    // PII
    pii81: '',
    pii71: '',
    pii61: '',
    pii51: '',
    pii41: '',
    pii31: '',
    pii21: '',
    pii11: '',
    pii12: '',
    pii22: '',
    pii32: '',
    pii42: '',
    pii52: '',
    pii62: '',
    pii72: '',
    pii82: '',

    // GI
    gi81: '',
    gi71: '',
    gi61: '',
    gi51: '',
    gi41: '',
    gi31: '',
    gi21: '',
    gi11: '',
    gi12: '',
    gi22: '',
    gi32: '',
    gi42: '',
    gi52: '',
    gi62: '',
    gi72: '',
    gi82: '',

    // PPD
    ppd81: '',
    ppd71: '',
    ppd61: '',
    ppd51: '',
    ppd41: '',
    ppd31: '',
    ppd21: '',
    ppd11: '',
    ppd12: '',
    ppd22: '',
    ppd32: '',
    ppd42: '',
    ppd52: '',
    ppd62: '',
    ppd72: '',
    ppd82: '',

    // GM
    gm81: '',
    gm71: '',
    gm61: '',
    gm51: '',
    gm41: '',
    gm31: '',
    gm21: '',
    gm11: '',
    gm12: '',
    gm22: '',
    gm32: '',
    gm42: '',
    gm52: '',
    gm62: '',
    gm72: '',
    gm82: '',

    // CAL
    cal81: '',
    cal71: '',
    cal61: '',
    cal51: '',
    cal41: '',
    cal31: '',
    cal21: '',
    cal11: '',
    cal12: '',
    cal22: '',
    cal32: '',
    cal42: '',
    cal52: '',
    cal62: '',
    cal72: '',
    cal82: '',

    // BOP
    bop81: '',
    bop71: '',
    bop61: '',
    bop51: '',
    bop41: '',
    bop31: '',
    bop21: '',
    bop11: '',
    bop12: '',
    bop22: '',
    bop32: '',
    bop42: '',
    bop52: '',
    bop62: '',
    bop72: '',
    bop82: ''
  },

  p4table3: {
    // lung lay
    lunglay81: '',
    lunglay71: '',
    lunglay61: '',
    lunglay51: '',
    lunglay41: '',
    lunglay31: '',
    lunglay21: '',
    lunglay11: '',
    lunglay12: '',
    lunglay22: '',
    lunglay32: '',
    lunglay42: '',
    lunglay52: '',
    lunglay62: '',
    lunglay72: '',
    lunglay82: '',

    // PII
    pii81: '',
    pii71: '',
    pii61: '',
    pii51: '',
    pii41: '',
    pii31: '',
    pii21: '',
    pii11: '',
    pii12: '',
    pii22: '',
    pii32: '',
    pii42: '',
    pii52: '',
    pii62: '',
    pii72: '',
    pii82: '',

    // GI
    gi81: '',
    gi71: '',
    gi61: '',
    gi51: '',
    gi41: '',
    gi31: '',
    gi21: '',
    gi11: '',
    gi12: '',
    gi22: '',
    gi32: '',
    gi42: '',
    gi52: '',
    gi62: '',
    gi72: '',
    gi82: '',

    // PPD
    ppd81: '',
    ppd71: '',
    ppd61: '',
    ppd51: '',
    ppd41: '',
    ppd31: '',
    ppd21: '',
    ppd11: '',
    ppd12: '',
    ppd22: '',
    ppd32: '',
    ppd42: '',
    ppd52: '',
    ppd62: '',
    ppd72: '',
    ppd82: '',

    // GM
    gm81: '',
    gm71: '',
    gm61: '',
    gm51: '',
    gm41: '',
    gm31: '',
    gm21: '',
    gm11: '',
    gm12: '',
    gm22: '',
    gm32: '',
    gm42: '',
    gm52: '',
    gm62: '',
    gm72: '',
    gm82: '',

    // CAL
    cal81: '',
    cal71: '',
    cal61: '',
    cal51: '',
    cal41: '',
    cal31: '',
    cal21: '',
    cal11: '',
    cal12: '',
    cal22: '',
    cal32: '',
    cal42: '',
    cal52: '',
    cal62: '',
    cal72: '',
    cal82: '',

    // BOP
    bop81: '',
    bop71: '',
    bop61: '',
    bop51: '',
    bop41: '',
    bop31: '',
    bop21: '',
    bop11: '',
    bop12: '',
    bop22: '',
    bop32: '',
    bop42: '',
    bop52: '',
    bop62: '',
    bop72: '',
    bop82: ''
  },

  p4table4: {
    // lung lay
    lunglay81: '',
    lunglay71: '',
    lunglay61: '',
    lunglay51: '',
    lunglay41: '',
    lunglay31: '',
    lunglay21: '',
    lunglay11: '',
    lunglay12: '',
    lunglay22: '',
    lunglay32: '',
    lunglay42: '',
    lunglay52: '',
    lunglay62: '',
    lunglay72: '',
    lunglay82: '',

    // PII
    pii81: '',
    pii71: '',
    pii61: '',
    pii51: '',
    pii41: '',
    pii31: '',
    pii21: '',
    pii11: '',
    pii12: '',
    pii22: '',
    pii32: '',
    pii42: '',
    pii52: '',
    pii62: '',
    pii72: '',
    pii82: '',

    // GI
    gi81: '',
    gi71: '',
    gi61: '',
    gi51: '',
    gi41: '',
    gi31: '',
    gi21: '',
    gi11: '',
    gi12: '',
    gi22: '',
    gi32: '',
    gi42: '',
    gi52: '',
    gi62: '',
    gi72: '',
    gi82: '',

    // PPD
    ppd81: '',
    ppd71: '',
    ppd61: '',
    ppd51: '',
    ppd41: '',
    ppd31: '',
    ppd21: '',
    ppd11: '',
    ppd12: '',
    ppd22: '',
    ppd32: '',
    ppd42: '',
    ppd52: '',
    ppd62: '',
    ppd72: '',
    ppd82: '',

    // GM
    gm81: '',
    gm71: '',
    gm61: '',
    gm51: '',
    gm41: '',
    gm31: '',
    gm21: '',
    gm11: '',
    gm12: '',
    gm22: '',
    gm32: '',
    gm42: '',
    gm52: '',
    gm62: '',
    gm72: '',
    gm82: '',

    // CAL
    cal81: '',
    cal71: '',
    cal61: '',
    cal51: '',
    cal41: '',
    cal31: '',
    cal21: '',
    cal11: '',
    cal12: '',
    cal22: '',
    cal32: '',
    cal42: '',
    cal52: '',
    cal62: '',
    cal72: '',
    cal82: '',

    // BOP
    bop81: '',
    bop71: '',
    bop61: '',
    bop51: '',
    bop41: '',
    bop31: '',
    bop21: '',
    bop11: '',
    bop12: '',
    bop22: '',
    bop32: '',
    bop42: '',
    bop52: '',
    bop62: '',
    bop72: '',
    bop82: ''
  },

  // page 5
  tomtat: '',
  chandoan1: '',
  chandoan2: '',
  chandoan3: '',
  chandoan4: '',
  chandoan5: '',

  // page 6
  row1: {
    luuy: '',
    xutri: ''
  },
  row2: {
    luuy: '',
    xutri: ''
  },
  row3: {
    luuy: '',
    xutri: ''
  },
  row4: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row5: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row6: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row7: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row8: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row9: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row10: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row11: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row12: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row13: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row14: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row15: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row16: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row17: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row18: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  row19: {
    loai: '',
    gia: '',
    sobuoi: ''
  },
  rowtotal: {
    gia: '',
    sobuoi: ''
  },

  // page 7
  ques1: 3,
  ques2: 3,
  ques3: '',
  ques4: 'no', // assuming 'no' as the default value
  ques5: 3,
  ques6: '',
  ques7: '',
  datepage7first: '',

  ques21: 3,
  ques22: 3,
  ques23: 3,
  ques24: 3,
  ques25: 'no', // assuming 'no' as the default value
  ques26: 3,
  benhnhangopy: '',
  datepage7second: '',

  // page 8
  p8row1: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row2: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row3: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row4: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row5: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row6: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row7: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row8: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row9: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row10: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row11: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row12: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row13: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row14: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },
  p8row15: {
    rang: '',
    noidung: '',
    svdieutri: '',
    svtrothu: '',
    mentor: '',
    note: ''
  },

  // page 9
  page9: {
    row1col1: '',
    row1col2: '',
    row1col3: '',
    row1col4: '',
    row2col1: '',
    row2col2: '',
    row2col3: '',
    row2col4: '',

    note1: '',
    note2: '',
    note3: '',
    note4: ''
  },

  // page 10
  giaotiep: {
    row1: '1',
    row2: '1',
    row3: '1',
    row4: '1',
    row5: '1',
    row6: '1',
    note: ''
  },

  tontrong: {
    row1: '1',
    row2: '1',
    row3: '1',
    row4: '1',
    note: ''
  },

  daoduc: {
    row1: '1',
    row2: '1',
    row3: '1',
    row4: '1',
    row5: '1',
    note: ''
  },

  thaido: {
    row1: '1',
    row2: '1',
    row3: '1',
    row4: '1',
    row5: '1',
    row6: '1',
    note: ''
  },

  chuanbi: {
    row1: '1',
    row2: '1',
    row3: '1',
    row4: '1',
    row5: '1',
    note: ''
  }
}
