/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
import type { MenuProps } from 'antd'
import { Layout, Menu, theme } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { ROLE } from '@/common/enum'
import { fetchMe } from '@/common/reducers/meReducer'
import { logout } from '@/modules/auth/reducers/authReducer'
import type { RootState } from '@/store'

const { useToken } = theme
const { Header, Content } = Layout

type MenuItem = Required<MenuProps>['items'][number]

const MainLayout: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth.user)
  const navigate: NavigateFunction = useNavigate()
  const location = useLocation()
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn)
  const guestRoutes = ['/oauth/google', '/login']

  const dispatch = useDispatch<any>()

  const menuItems: MenuItem[] = [
    {
      key: '/info',
      label: (
        <Link to='/info' className='transition-none'>
          Thông tin
        </Link>
      )
    },

    {
      key: '/medical-records',
      label: (
        <Link to='/medical-records' className='transition-none'>
          Quản lý hồ sơ
        </Link>
      )
    },
    {
      key: '/doctors',
      label: (
        <Link to='/doctors' className='transition-none'>
          Danh sách bác sĩ
        </Link>
      )
    },
    {
      key: '/organization',
      label: (
        <Link to='/organization' className='transition-none'>
          Sơ đồ tổ chức
        </Link>
      )
    },
    (user?.role === ROLE.ADMIN || user?.role === ROLE.SUPERADMIN) && {
      key: '/authorizations',
      label: (
        <Link to='/authorizations' className='transition-none'>
          Phân quyền
        </Link>
      )
    },
    (user?.role === ROLE.ADMIN || user?.role === ROLE.SUPERADMIN) && {
      key: '/settings',
      label: (
        <Link to='/settings' className='transition-none'>
          Cài đặt
        </Link>
      )
    },
    {
      key: '/account',
      label: (
        <Link to='/account' className='transition-none'>
          Tài khoản
        </Link>
      )
    }
  ].filter(Boolean) as MenuItem[]

  const handleTabChange = (key: string) => {
    navigate(key)
  }

  const handleLogout = async () => {
    await dispatch(logout())
    navigate('/login')
  }

  const getSelectedKeys = () => {
    if (location.pathname.startsWith('/medical-records')) {
      return ['/medical-records']
    }
    if (location.pathname.startsWith('/doctors')) {
      return ['/doctors']
    }
    if (location.pathname.startsWith('/organization')) {
      return ['/organization']
    }
    if (location.pathname.startsWith('/authorizations')) {
      return ['/authorizations']
    }
    if (location.pathname.startsWith('/account')) {
      return ['/account']
    }
    if (location.pathname.startsWith('/settings')) {
      return ['/settings']
    }
    return [location.pathname] // Mặc định cho các đường dẫn khác
  }

  const { token } = useToken()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchMe())
    }

    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }
  }, [])

  useEffect(() => {
    if (!guestRoutes.includes(location.pathname)) {
      if (!isLoggedIn) {
        navigate('/login')
      }
    }
  }, [location])

  return (
    <Layout className='min-h-screen'>
      <Header className='sticky top-0 z-10 flex w-full items-center justify-between px-0'>
        <Menu
          mode='horizontal'
          className='w-full justify-center'
          defaultSelectedKeys={[location.pathname]}
          selectedKeys={getSelectedKeys()}
          items={menuItems}
          style={{ flex: 1, minWidth: 0 }}
        />
      </Header>

      <Layout>
        <Layout className='p-6'>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: token.colorBgContainer,
              borderRadius: token.borderRadiusLG
            }}
          >
            <div style={{ maxWidth: '1200px', margin: 'auto' }}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default MainLayout
