import type { TreeProps } from 'antd'
import { Button, Tree } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import { ROLE } from '@/common/enum'
import type { RootState } from '@/store'

import { getOrganizationTree } from './reducers/organizationReducer'

const Organization: React.FC = () => {
  const navigate: NavigateFunction = useNavigate()
  const dispatch = useDispatch<any>()
  const user = useSelector((state: RootState) => state.auth.user)
  const [data, setData] = useState<any>([])
  const [expandedKeys, setExpandedKeys] = useState<string[]>([])

  const getAllKeys = (treeData: any[]): string[] => {
    let keys: string[] = []
    treeData.forEach((item) => {
      keys.push(item.key)
      if (item.children) {
        keys = keys.concat(getAllKeys(item.children))
      }
    })
    return keys
  }

  const fectchData = async () => {
    try {
      const rs = await dispatch(getOrganizationTree()).unwrap()
      setData(rs)
      if (!rs || rs.length === 0) return
      const keys = getAllKeys(rs)
      setExpandedKeys(keys)
    } catch (error) {}
  }

  const onSelect: TreeProps['onSelect'] = (selectedKeys, info) => {
    const key = info.node.key as string
    if (expandedKeys.includes(key)) {
      setExpandedKeys(expandedKeys.filter((k) => k !== key))
    } else {
      setExpandedKeys([...expandedKeys, key])
    }
  }

  const titleRender = (nodeData: any) => {
    const isParent = nodeData.key.includes('department')
    return <span className={isParent ? 'text-lg font-bold' : 'text-base'}>{nodeData.title}</span>
  }

  useEffect(() => {
    fectchData()
  }, [])

  return (
    <div>
      <div className='mb-4 flex justify-end gap-2'>
        {(user?.role === ROLE.ADMIN || user?.role === ROLE.SUPERADMIN) && (
          <Button type='primary' onClick={() => navigate('departments')}>
            Quản lý khoa
          </Button>
        )}
      </div>
      <Tree showLine defaultExpandAll expandedKeys={expandedKeys} onSelect={onSelect} treeData={data} titleRender={titleRender} />
    </div>
  )
}

export default Organization
