/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Form, Input, InputNumber, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate } from 'react-router-dom'

import { ROLE } from '@/common/enum'
import type { RootState } from '@/store'

import { getCodeFormat, getDoctorText, updateCodeFormat, updateDoctorText } from './reducers/settingReducer'

const SettingIndex: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()
  const [formText] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState(false)
  const currentUser = useSelector((state: RootState) => state.auth.user)
  const isAdmin = currentUser?.role === ROLE.ADMIN || currentUser?.role === ROLE.SUPERADMIN

  const fetchData = async () => {
    try {
      const rs = await dispatch(getCodeFormat()).unwrap()
      const rs2 = await dispatch(getDoctorText()).unwrap()
      form.setFieldsValue(rs.value)
      formText.setFieldsValue(rs2.value)
    } catch (error) {
      message.error('Đã xảy ra lỗi. Vui lòng thử lại')
    }
  }

  const handleFinish = async (values: any) => {
    setLoading(true)
    try {
      values.prefix = values.prefix.trim()

      await dispatch(updateCodeFormat({ ...values })).unwrap()
      message.success('Cập nhật thành công')
    } catch (error) {
      message.error('Đã xảy ra lỗi khi cập nhật')
    } finally {
      setLoading(false)
    }
  }

  const handleFinishText = async (values: any) => {
    setLoadingText(true)
    try {
      values.intern = values.intern.trim()
      values.doctor = values.doctor.trim()
      values.head = values.head.trim()

      await dispatch(updateDoctorText({ ...values })).unwrap()
      message.success('Cập nhật thành công')
    } catch (error) {
      message.error('Đã xảy ra lỗi khi cập nhật')
    } finally {
      setLoadingText(false)
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> & { target: HTMLInputElement & { value: string } }) => {
    const sanitizedValue = e.target.value.toUpperCase().replace(/[^a-zA-Z0-9]/g, '')
    form.setFieldsValue({ prefix: sanitizedValue })
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='container mx-auto p-4'>
      <Outlet />
      <div className='mb-4 flex items-center justify-between'>
        <h1 className='m-0 text-2xl font-bold'>Cài đặt</h1>
      </div>
      <Form form={form} onFinish={handleFinish} layout='vertical'>
        <h2 className='m-0 mb-3 text-lg font-bold'>Mã hồ sơ</h2>
        <div className='grid grid-cols-4 gap-4'>
          <Form.Item name='prefix' label='Text' rules={[{ required: true, message: 'Vui lòng không để trống' }]}>
            <Input onChange={handleChange} />
          </Form.Item>
          <div className='col-span-2'>
            <Form.Item
              name='number'
              label='Số chữ số ngẫu nhiên'
              rules={[
                { required: true, message: 'Vui lòng nhập' },
                { type: 'number', min: 4, max: 12, message: 'Số chữ số ngẫu nhiên phải từ 4 đến 12' }
              ]}
            >
              <InputNumber min={4} max={12} />
            </Form.Item>
          </div>
          <Form.Item className='mb-6 flex items-end'>
            <Button type='primary' htmlType='submit' loading={loading}>
              Lưu
            </Button>
          </Form.Item>
        </div>
      </Form>

      <Form form={formText} onFinish={handleFinishText} layout='vertical'>
        <h2 className='font-bol m-0 mb-3 text-lg'>Chức vụ</h2>
        <div className='grid grid-cols-4 gap-4'>
          <Form.Item name='intern' label='Học viên' rules={[{ required: true, message: 'Vui lòng không để trống' }]}>
            <Input
              onChange={(e: any) => {
                formText.setFieldsValue({ intern: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item name='doctor' label='Bác sĩ' rules={[{ required: true, message: 'Vui lòng không để trống' }]}>
            <Input
              onChange={(e: any) => {
                formText.setFieldsValue({ doctor: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item name='head' label='Trưởng khoa' rules={[{ required: true, message: 'Vui lòng không để trống' }]}>
            <Input
              onChange={(e: any) => {
                formText.setFieldsValue({ head: e.target.value })
              }}
            />
          </Form.Item>
          <Form.Item className='mb-6 flex items-end'>
            <Button type='primary' htmlType='submit' loading={loadingText}>
              Lưu
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}

export default SettingIndex
