import { Button, Form, Input, message, Modal, Table } from 'antd'
import type { ColumnType } from 'antd/es/table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ROLE } from '@/common/enum'
import type { RootState } from '@/store'

import { addDepartment, deleteDepartment, editDepartment, getAllDepartments } from './reducers/organizationReducer'

interface Department {
  id: number
  name: string
  doctorCount: number
  doctorHead: string
  description: string
}

const DepartmentList: React.FC = () => {
  const dispatch = useDispatch<any>()
  const items = useSelector((state: RootState) => state.organization.items) as Department[]
  const [loading, setLoading] = useState<boolean>(true)
  const currentUser = useSelector((state: RootState) => state.auth.user)
  const normalDoctor = currentUser?.role === ROLE.DOCTOR && currentUser?.doctor?.isHead === false

  const [modalVisible, setModalVisible] = useState<boolean>(false)
  const [currentDepartment, setCurrentDepartment] = useState<any>(null)
  const [modalConfirm, setModalConfirm] = useState<boolean>(false)

  const [form] = Form.useForm()

  const fectchData = async () => {
    setLoading(true)
    try {
      await dispatch(getAllDepartments())
    } catch (error) {
    } finally {
      setLoading(false)
    }
  }

  const handleAdd = () => {
    setCurrentDepartment(null)
    form.resetFields()
    setModalVisible(true)
  }

  const handleEdit = (record: Department) => {
    setCurrentDepartment(record)
    form.setFieldsValue({
      name: record.name,
      description: record.description
    })
    setModalVisible(true)
  }

  const handleDelete = (id: number) => {
    setCurrentDepartment({ id })
    setModalConfirm(true)
  }

  const handleCancel = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields()

      if (currentDepartment?.id) {
        await dispatch(editDepartment({ id: currentDepartment.id, ...values }))
        message.success('Cập nhật khoa thành công')
      } else {
        await dispatch(addDepartment(values))
        message.success('Thêm khoa thành công')
      }

      setModalVisible(false)
      form.resetFields()
    } catch (error) {}
  }

  const confirmDelete = async () => {
    try {
      await dispatch(deleteDepartment(currentDepartment.id)).unwrap()
      message.success('Xóa khoa thành công')
      setModalConfirm(false)
    } catch (error) {
      message.error('Không thể xóa nếu khoa này đang có bác sĩ')
    }
  }

  useEffect(() => {
    fectchData()
  }, [])

  const columns = [
    {
      title: 'Tên khoa',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Số lượng bác sĩ',
      dataIndex: 'doctorCount',
      key: 'doctorCount'
    },
    {
      title: 'Trưởng khoa',
      dataIndex: 'doctorHead',
      key: 'doctorHead'
    },
    {
      title: 'Hành động',
      key: 'action',
      render: (text: any, record: Department) => (
        <div className='flex gap-2'>
          <Button type='primary' onClick={() => handleEdit(record)}>
            Sửa
          </Button>
          <Button type='primary' danger onClick={() => handleDelete(record.id)}>
            Xóa
          </Button>
        </div>
      )
    }
  ].filter(Boolean) as ColumnType<any>[]

  return (
    <div>
      <Modal
        title={currentDepartment?.id ? 'Sửa khoa' : 'Thêm khoa'}
        open={modalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={currentDepartment?.id ? 'Cập nhật' : 'Lưu'}
        cancelText='Hủy'
      >
        <Form form={form} layout='vertical'>
          <Form.Item label='Tên khoa' name='name' rules={[{ required: true, message: 'Vui lòng nhập tên khoa' }]}>
            <Input />
          </Form.Item>

          <Form.Item label='Mô tả' name='description'>
            <Input.TextArea rows={3} />
          </Form.Item>
        </Form>
      </Modal>

      {/* modal confirm delete */}
      <Modal
        title='Xác nhận xóa khoa'
        open={modalConfirm}
        onCancel={() => setModalConfirm(false)}
        onOk={confirmDelete}
        okText='Xóa'
        okButtonProps={{ danger: true }}
      >
        <p>Bạn có chắc chắn muốn xóa khoa này không?</p>
        <p>Hành động này không thể thực hiện nếu khoa này đang có bác sĩ.</p>
      </Modal>

      <div className='mb-4 flex justify-end gap-2'>
        {!normalDoctor && (
          <Button type='primary' onClick={handleAdd}>
            Thêm khoa
          </Button>
        )}
      </div>

      <Table dataSource={items} columns={columns} rowKey='id' loading={loading} rowClassName='cursor-pointer' />
    </div>
  )
}

export default DepartmentList
