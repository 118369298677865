/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import fontkit from '@pdf-lib/fontkit'
// import { saveAs } from 'file-saver'
import { PDFDocument, rgb } from 'pdf-lib'

import type { MedicalFormState } from './constant'

const fontUrl = `${window.location.origin}/fonts/Times-New-Roman.ttf`

export const generatePDF = async (data: MedicalFormState, templateUrl: string, signature: string | null, headName: string | null, media: any) => {
  const existingPdfBytes = await fetch(templateUrl).then((res) => res.arrayBuffer())

  const imgMedia = media.filter((item: any) => item.fileType !== 'mp4')

  const pdfDoc = await PDFDocument.load(existingPdfBytes)

  pdfDoc.registerFontkit(fontkit)

  const fontBytes = await fetch(fontUrl).then((res) => res.arrayBuffer())
  const customFont = await pdfDoc.embedFont(fontBytes)

  const pages = pdfDoc.getPages()

  const fontSize = 11
  const color = rgb(0, 0, 0)

  let image: any = null
  let imageDims: any = null

  if (signature) {
    try {
      const imageUrl = signature
      const imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer())

      const uint8Array = new Uint8Array(imageBytes)

      const isPng =
        uint8Array[0] === 0x89 &&
        uint8Array[1] === 0x50 &&
        uint8Array[2] === 0x4e &&
        uint8Array[3] === 0x47 &&
        uint8Array[4] === 0x0d &&
        uint8Array[5] === 0x0a &&
        uint8Array[6] === 0x1a &&
        uint8Array[7] === 0x0a

      const isJpg = uint8Array[0] === 0xff && uint8Array[1] === 0xd8 && uint8Array[uint8Array.length - 2] === 0xff && uint8Array[uint8Array.length - 1] === 0xd9

      if (isPng) {
        image = await pdfDoc.embedPng(imageBytes)
      } else if (isJpg) {
        image = await pdfDoc.embedJpg(imageBytes)
      } else {
        throw new Error('Định dạng ảnh không được hỗ trợ')
      }

      const imageHeight = image.height
      const scale = 70 / imageHeight
      imageDims = image.scale(scale)
    } catch (error) {}
  }

  if (imgMedia.length > 0) {
    for (const item of imgMedia) {
      let mediaImage = null
      let mediaDims = null
      const newPage = pdfDoc.addPage([612, 792])

      const mediaUrl = `${process.env.REACT_APP_BASE_API_URL}${item.fileUrl}`
      const mediaBytes = await fetch(mediaUrl).then((res) => res.arrayBuffer())
      const uint8Array = new Uint8Array(mediaBytes)

      const isPng =
        uint8Array[0] === 0x89 &&
        uint8Array[1] === 0x50 &&
        uint8Array[2] === 0x4e &&
        uint8Array[3] === 0x47 &&
        uint8Array[4] === 0x0d &&
        uint8Array[5] === 0x0a &&
        uint8Array[6] === 0x1a &&
        uint8Array[7] === 0x0a

      const isJpg = uint8Array[0] === 0xff && uint8Array[1] === 0xd8 && uint8Array[uint8Array.length - 2] === 0xff && uint8Array[uint8Array.length - 1] === 0xd9

      if (isPng) {
        mediaImage = await pdfDoc.embedPng(mediaBytes)
      } else if (isJpg) {
        mediaImage = await pdfDoc.embedJpg(mediaBytes)
      } else {
        throw new Error('Định dạng ảnh không được hỗ trợ')
      }

      const pageWidth = 612
      const margin = 20
      const desiredWidth = pageWidth - margin * 2

      const scale = desiredWidth / mediaImage.width
      mediaDims = mediaImage.scale(scale)

      const xPos = margin
      const yPos = (792 - mediaDims.height) / 2

      newPage.drawImage(mediaImage, {
        x: xPos,
        y: yPos,
        width: mediaDims.width,
        height: mediaDims.height
      })
    }
  }

  pages.forEach((page, index) => {
    if (index === 0) {
      page.drawText(`${data.code}`, { x: 155, y: 698, size: fontSize, font: customFont, color })
      page.drawText(`${data.student}`, { x: 194, y: 685, size: fontSize, font: customFont, color })

      page.drawText(`${data.patient.name}`, { x: 125, y: 643.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.dob}`, { x: 378, y: 643.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.job}`, { x: 135, y: 628.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.education}`, { x: 408, y: 628.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.gender}`, { x: 118, y: 614.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.nation}`, { x: 412, y: 614.5, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.contact}`, { x: 186, y: 599, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.address}`, { x: 382, y: 599, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.contactPerson}`, { x: 261, y: 585, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.firstExam}`, { x: 167, y: 570.8, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.reason}`, { x: 404, y: 570.8, size: fontSize, font: customFont, color })
      page.drawText(`${data.patient.history}`, { x: 115, y: 556, size: fontSize, font: customFont, color })

      page.drawText(`${data.weight} kg`, { x: 260, y: 516, size: fontSize, font: customFont, color })
      page.drawText(`${data.height} cm`, { x: 414, y: 516, size: fontSize, font: customFont, color })

      if (data.sick.diung) {
        page.drawText('v', { x: 243, y: 503, size: fontSize, font: customFont, color })
      }
      if (data.sick.bamsinh) {
        page.drawText('v', { x: 243, y: 488.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.hutthuoc) {
        page.drawText('v', { x: 243, y: 474, size: fontSize, font: customFont, color })
      }
      if (data.sick.ruou) {
        page.drawText('v', { x: 243, y: 459.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.maydieuhoa) {
        page.drawText('v', { x: 243, y: 445, size: fontSize, font: customFont, color })
      }
      if (data.sick.stentmachmau) {
        page.drawText('v', { x: 243, y: 430.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.mangthai) {
        page.drawText('v', { x: 394, y: 503, size: fontSize, font: customFont, color })
      }
      if (data.sick.choconbu) {
        page.drawText('v', { x: 394, y: 488.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.bingatxiu) {
        page.drawText('v', { x: 394, y: 474, size: fontSize, font: customFont, color })
      }
      if (data.sick.viemkhop) {
        page.drawText('v', { x: 394, y: 459.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.viemgan) {
        page.drawText('v', { x: 394, y: 445, size: fontSize, font: customFont, color })
      }
      if (data.sick.lao) {
        page.drawText('v', { x: 394, y: 430.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.caohuyetap) {
        page.drawText('v', { x: 545, y: 503, size: fontSize, font: customFont, color })
      }
      if (data.sick.daithaoduong) {
        page.drawText('v', { x: 545, y: 488.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.benhtim) {
        page.drawText('v', { x: 545, y: 474, size: fontSize, font: customFont, color })
      }
      if (data.sick.benhmau) {
        page.drawText('v', { x: 545, y: 459.5, size: fontSize, font: customFont, color })
      }
      if (data.sick.benhthan) {
        page.drawText('v', { x: 545, y: 445, size: fontSize, font: customFont, color })
      }
      if (data.sick.benhsuyen) {
        page.drawText('v', { x: 545, y: 430.5, size: fontSize, font: customFont, color })
      }

      // tamly
      if (data.tamly.khotiepcan) {
        page.drawText('v', { x: 245, y: 329, size: fontSize, font: customFont, color })
      }
      if (data.tamly.khohoptac) {
        page.drawText('v', { x: 245, y: 314.5, size: fontSize, font: customFont, color })
      }
      if (data.tamly.yeucauthammy) {
        page.drawText('v', { x: 394.5, y: 329, size: fontSize, font: customFont, color })
      }
      if (data.tamly.tulamhai) {
        page.drawText('v', { x: 394.5, y: 314.5, size: fontSize, font: customFont, color })
      }
      if (data.tamly.vandetaichinh) {
        page.drawText('v', { x: 546, y: 329, size: fontSize, font: customFont, color })
      }
      if (data.tamly.loausohai) {
        page.drawText('v', { x: 546, y: 314.5, size: fontSize, font: customFont, color })
      }

      // dental
      if (data.dental.reasonHistory) {
        page.drawText(`${data.dental.reasonHistory}`, { x: 234, y: 264, size: fontSize, font: customFont, color })
      }
      if (data.dental.dungtam) {
        page.drawText('v', { x: 544, y: 238.5, size: fontSize, font: customFont, color })
      }
      if (data.dental.dungchi) {
        page.drawText('v', { x: 544, y: 224, size: fontSize, font: customFont, color })
      }
      if (data.dental.dungbanchai) {
        page.drawText('v', { x: 544, y: 210, size: fontSize, font: customFont, color })
      }
      if (data.dental.chaihangngay) {
        page.drawText('v', { x: 315, y: 210, size: fontSize, font: customFont, color })
      }
      if (data.dental.khamdinhky) {
        page.drawText('v', { x: 315, y: 224, size: fontSize, font: customFont, color })
      }
      if (data.dental.cachchairang) {
        page.drawText(`${data.dental.cachchairang}`, { x: 78, y: 184, size: fontSize, font: customFont, color })
      }
      if (data.dental.anthucphamcung) {
        page.drawText('v', { x: 315, y: 159, size: fontSize, font: customFont, color })
      }
      if (data.dental.anthucphamngot) {
        page.drawText('v', { x: 315, y: 144, size: fontSize, font: customFont, color })
      }
      if (data.dental.anthucphamchua) {
        page.drawText('v', { x: 544, y: 159, size: fontSize, font: customFont, color })
      }
      if (data.dental.uongnuocgacoga) {
        page.drawText('v', { x: 544, y: 144, size: fontSize, font: customFont, color })
      }

      // thoiquenrang
      if (data.thoiquenrang.nhaimotben) {
        page.drawText('v', { x: 245.5, y: 107, size: fontSize, font: customFont, color })
      }
      if (data.thoiquenrang.nhaivatcung) {
        page.drawText('v', { x: 245.5, y: 92.8, size: fontSize, font: customFont, color })
      }

      if (data.thoiquenrang.canmoi) {
        page.drawText('v', { x: 394, y: 107, size: fontSize, font: customFont, color })
      }
      if (data.thoiquenrang.tatdayluoi) {
        page.drawText('v', { x: 394, y: 92.8, size: fontSize, font: customFont, color })
      }

      if (data.thoiquenrang.nghienrangkhingu) {
        page.drawText('v', { x: 542.5, y: 107, size: fontSize, font: customFont, color })
      }
      if (data.thoiquenrang.nghienchatrang) {
        page.drawText('v', { x: 542.5, y: 92.8, size: fontSize, font: customFont, color })
      }
    } else if (index === 1) {
      page.drawText(`${data.ngoaimat}`, { x: 70, y: 535, size: fontSize, font: customFont, color })
      page.drawText(`${data.trongmieng}`, { x: 352, y: 440, size: fontSize, font: customFont, color })

      // cankhop
      page.drawText(`${data.cankhop.anglephai}`, { x: 171, y: 216, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.angletrai}`, { x: 245, y: 216, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.canphu}`, { x: 353, y: 216, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.canchia}`, { x: 498, y: 216, size: fontSize, font: customFont, color })

      page.drawText(`${data.cankhop.biendo}`, { x: 203, y: 201, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.dolech}`, { x: 327, y: 201, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.tinhchat}`, { x: 447, y: 201, size: fontSize, font: customFont, color })

      // table
      page.drawText(`${data.cankhop.row1col1}`, { x: 128, y: 162, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.row1col2}`, { x: 264, y: 162, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.row1col3}`, { x: 408, y: 162, size: fontSize, font: customFont, color })

      page.drawText(`${data.cankhop.row2col2}`, { x: 264, y: 147, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.row2col3}`, { x: 408, y: 147, size: fontSize, font: customFont, color })

      page.drawText(`${data.cankhop.row3col2}`, { x: 264, y: 131, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhop.row3col3}`, { x: 408, y: 131, size: fontSize, font: customFont, color })

      page.drawText(`${data.congspee}`, { x: 163, y: 111, size: fontSize, font: customFont, color })
      page.drawText(`${data.congwilson}`, { x: 175, y: 95, size: fontSize, font: customFont, color })
      page.drawText(`${data.cankhopkhac}`, { x: 196, y: 79, size: fontSize, font: customFont, color })
    } else if (index === 2) {
      page.drawText(`${data.tiepxucrang.row1col2}`, { x: 259, y: 707, size: fontSize, font: customFont, color })
      page.drawText(`${data.tiepxucrang.row1col3}`, { x: 396, y: 707, size: fontSize, font: customFont, color })

      page.drawText(`${data.tiepxucrang.row2col2}`, { x: 259, y: 693, size: fontSize, font: customFont, color })
      page.drawText(`${data.tiepxucrang.row2col3}`, { x: 396, y: 693, size: fontSize, font: customFont, color })

      page.drawText(`${data.tiepxucrang.row3col2}`, { x: 259, y: 681, size: fontSize, font: customFont, color })
      page.drawText(`${data.tiepxucrang.row3col3}`, { x: 396, y: 681, size: fontSize, font: customFont, color })

      page.drawText(`${data.rang11}`, { x: 163, y: 640, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang12}`, { x: 163, y: 625, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang13}`, { x: 163, y: 610, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang14}`, { x: 163, y: 594, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang15}`, { x: 163, y: 579, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang16}`, { x: 163, y: 563, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang17}`, { x: 163, y: 546, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang18}`, { x: 163, y: 529, size: fontSize, font: customFont, color })

      page.drawText(`${data.rang21}`, { x: 378, y: 640, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang22}`, { x: 378, y: 624, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang23}`, { x: 378, y: 609, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang24}`, { x: 378, y: 594, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang25}`, { x: 378, y: 579, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang26}`, { x: 378, y: 561, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang27}`, { x: 378, y: 545, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang28}`, { x: 378, y: 529, size: fontSize, font: customFont, color })

      page.drawText(`${data.rang31}`, { x: 378, y: 389, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang32}`, { x: 378, y: 403, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang33}`, { x: 378, y: 418, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang34}`, { x: 378, y: 434, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang35}`, { x: 378, y: 448, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang36}`, { x: 378, y: 466, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang37}`, { x: 378, y: 483, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang38}`, { x: 378, y: 501, size: fontSize, font: customFont, color })

      page.drawText(`${data.rang41}`, { x: 163, y: 388, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang42}`, { x: 163, y: 403, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang43}`, { x: 163, y: 419, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang44}`, { x: 163, y: 433, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang45}`, { x: 163, y: 448, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang46}`, { x: 163, y: 466, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang47}`, { x: 163, y: 483, size: fontSize, font: customFont, color })
      page.drawText(`${data.rang48}`, { x: 163, y: 501, size: fontSize, font: customFont, color })

      // canlamsang
      if (data.canlamsang.phimquanhkhop) {
        page.drawText('v', { x: 289, y: 316, size: fontSize, font: customFont, color })
      }
      if (data.canlamsang.mauham) {
        page.drawText('v', { x: 531, y: 316, size: fontSize, font: customFont, color })
      }
      if (data.canlamsang.toancanh) {
        page.drawText('v', { x: 289, y: 298.5, size: fontSize, font: customFont, color })
      }
      if (data.canlamsang.mau) {
        page.drawText('v', { x: 531, y: 298.5, size: fontSize, font: customFont, color })
      }
      if (data.canlamsang.cancanh) {
        page.drawText('v', { x: 289, y: 280.5, size: fontSize, font: customFont, color })
      }
      if (data.canlamsang.conebeam) {
        page.drawText('v', { x: 531, y: 280.5, size: fontSize, font: customFont, color })
      }
      if (data.canlamsang.khac) {
        page.drawText(data.canlamsang.khac, { x: 89, y: 264, size: fontSize, font: customFont, color })
      }
      if (data.canlamsang.nhanxet) {
        page.drawText(data.canlamsang.nhanxet, { x: 66, y: 226, size: fontSize, font: customFont, color })
      }
    } else if (index === 3) {
      page.drawText(`${data.patient.name}`, { x: 135, y: 747, size: fontSize - 1, font: customFont, color })
      page.drawText(`${data.code}`, { x: 351, y: 747, size: fontSize - 2, font: customFont, color })
      page.drawText(`${data.p4table1.ngay}`, { x: 476, y: 747, size: fontSize - 2, font: customFont, color })
      if (data.additionalLink) {
        page.drawText(`Liên kết đến biểu đồ trực tuyến: ${data.additionalLink}`, { x: 62, y: 762, size: fontSize - 2, font: customFont, color })
      }

      // p4table1
      if (data.p4table1) {
        // lunglay
        page.drawText(`${data.p4table1.lunglay81}`, { x: 106, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay71}`, { x: 132, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay61}`, { x: 161, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay51}`, { x: 190, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay41}`, { x: 220, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay31}`, { x: 248, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay21}`, { x: 272, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay11}`, { x: 298, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay12}`, { x: 324, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay22}`, { x: 350, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay32}`, { x: 377, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay42}`, { x: 401, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay52}`, { x: 427, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay62}`, { x: 455, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay72}`, { x: 481, y: 736, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.lunglay82}`, { x: 511, y: 736, size: fontSize - 3, font: customFont, color })

        // pii
        page.drawText(`${data.p4table1.pii81}`, { x: 106, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii71}`, { x: 132, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii61}`, { x: 161, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii51}`, { x: 190, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii41}`, { x: 220, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii31}`, { x: 248, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii21}`, { x: 272, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii11}`, { x: 298, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii12}`, { x: 324, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii22}`, { x: 350, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii32}`, { x: 377, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii42}`, { x: 401, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii52}`, { x: 427, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii62}`, { x: 455, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii72}`, { x: 481, y: 715, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.pii82}`, { x: 511, y: 715, size: fontSize - 3, font: customFont, color })

        // gi
        page.drawText(`${data.p4table1.gi81}`, { x: 106, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi71}`, { x: 132, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi61}`, { x: 161, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi51}`, { x: 190, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi41}`, { x: 220, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi31}`, { x: 248, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi21}`, { x: 272, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi11}`, { x: 298, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi12}`, { x: 324, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi22}`, { x: 350, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi32}`, { x: 377, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi42}`, { x: 401, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi52}`, { x: 427, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi62}`, { x: 455, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi72}`, { x: 481, y: 704, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gi82}`, { x: 511, y: 704, size: fontSize - 3, font: customFont, color })

        // ppd
        page.drawText(`${data.p4table1.ppd81}`, { x: 106, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd71}`, { x: 132, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd61}`, { x: 161, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd51}`, { x: 190, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd41}`, { x: 220, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd31}`, { x: 248, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd21}`, { x: 272, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd11}`, { x: 298, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd12}`, { x: 324, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd22}`, { x: 350, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd32}`, { x: 377, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd42}`, { x: 401, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd52}`, { x: 427, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd62}`, { x: 455, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd72}`, { x: 481, y: 693, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.ppd82}`, { x: 511, y: 693, size: fontSize - 3, font: customFont, color })

        // GM
        page.drawText(`${data.p4table1.gm81}`, { x: 106, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm71}`, { x: 132, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm61}`, { x: 161, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm51}`, { x: 190, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm41}`, { x: 220, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm31}`, { x: 248, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm21}`, { x: 272, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm11}`, { x: 298, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm12}`, { x: 324, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm22}`, { x: 350, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm32}`, { x: 377, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm42}`, { x: 401, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm52}`, { x: 427, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm62}`, { x: 455, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm72}`, { x: 481, y: 682, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.gm82}`, { x: 511, y: 682, size: fontSize - 3, font: customFont, color })

        // CAL
        page.drawText(`${data.p4table1.cal81}`, { x: 106, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal71}`, { x: 132, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal61}`, { x: 161, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal51}`, { x: 190, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal41}`, { x: 220, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal31}`, { x: 248, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal21}`, { x: 272, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal11}`, { x: 298, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal12}`, { x: 324, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal22}`, { x: 350, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal32}`, { x: 377, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal42}`, { x: 401, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal52}`, { x: 427, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal62}`, { x: 455, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal72}`, { x: 481, y: 670, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.cal82}`, { x: 511, y: 670, size: fontSize - 3, font: customFont, color })

        // BOP
        page.drawText(`${data.p4table1.bop81}`, { x: 106, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop71}`, { x: 132, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop61}`, { x: 161, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop51}`, { x: 190, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop41}`, { x: 220, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop31}`, { x: 248, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop21}`, { x: 272, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop11}`, { x: 298, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop12}`, { x: 324, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop22}`, { x: 350, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop32}`, { x: 377, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop42}`, { x: 401, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop52}`, { x: 427, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop62}`, { x: 455, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop72}`, { x: 481, y: 658, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table1.bop82}`, { x: 511, y: 658, size: fontSize - 3, font: customFont, color })
      }

      // p4table2
      if (data.p4table2) {
        // lunglay
        page.drawText(`${data.p4table2.lunglay81}`, { x: 106, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay71}`, { x: 132, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay61}`, { x: 161, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay51}`, { x: 190, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay41}`, { x: 220, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay31}`, { x: 248, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay21}`, { x: 272, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay11}`, { x: 298, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay12}`, { x: 324, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay22}`, { x: 350, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay32}`, { x: 377, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay42}`, { x: 401, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay52}`, { x: 427, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay62}`, { x: 455, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay72}`, { x: 481, y: 416, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.lunglay82}`, { x: 511, y: 416, size: fontSize - 3, font: customFont, color })

        // pii
        page.drawText(`${data.p4table2.pii81}`, { x: 106, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii71}`, { x: 132, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii61}`, { x: 161, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii51}`, { x: 190, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii41}`, { x: 220, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii31}`, { x: 248, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii21}`, { x: 272, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii11}`, { x: 298, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii12}`, { x: 324, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii22}`, { x: 350, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii32}`, { x: 377, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii42}`, { x: 401, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii52}`, { x: 427, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii62}`, { x: 455, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii72}`, { x: 481, y: 493, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.pii82}`, { x: 511, y: 493, size: fontSize - 3, font: customFont, color })

        // gi
        page.drawText(`${data.p4table2.gi81}`, { x: 106, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi71}`, { x: 132, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi61}`, { x: 161, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi51}`, { x: 190, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi41}`, { x: 220, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi31}`, { x: 248, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi21}`, { x: 272, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi11}`, { x: 298, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi12}`, { x: 324, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi22}`, { x: 350, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi32}`, { x: 377, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi42}`, { x: 401, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi52}`, { x: 427, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi62}`, { x: 455, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi72}`, { x: 481, y: 482, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gi82}`, { x: 511, y: 482, size: fontSize - 3, font: customFont, color })

        // ppd
        page.drawText(`${data.p4table2.ppd81}`, { x: 106, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd71}`, { x: 132, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd61}`, { x: 161, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd51}`, { x: 190, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd41}`, { x: 220, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd31}`, { x: 248, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd21}`, { x: 272, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd11}`, { x: 298, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd12}`, { x: 324, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd22}`, { x: 350, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd32}`, { x: 377, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd42}`, { x: 401, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd52}`, { x: 427, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd62}`, { x: 455, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd72}`, { x: 481, y: 472, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.ppd82}`, { x: 511, y: 472, size: fontSize - 3, font: customFont, color })

        // GM
        page.drawText(`${data.p4table2.gm81}`, { x: 106, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm71}`, { x: 132, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm61}`, { x: 161, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm51}`, { x: 190, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm41}`, { x: 220, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm31}`, { x: 248, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm21}`, { x: 272, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm11}`, { x: 298, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm12}`, { x: 324, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm22}`, { x: 350, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm32}`, { x: 377, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm42}`, { x: 401, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm52}`, { x: 427, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm62}`, { x: 455, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm72}`, { x: 481, y: 461, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.gm82}`, { x: 511, y: 461, size: fontSize - 3, font: customFont, color })

        // CAL
        page.drawText(`${data.p4table2.cal81}`, { x: 106, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal71}`, { x: 132, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal61}`, { x: 161, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal51}`, { x: 190, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal41}`, { x: 220, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal31}`, { x: 248, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal21}`, { x: 272, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal11}`, { x: 298, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal12}`, { x: 324, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal22}`, { x: 350, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal32}`, { x: 377, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal42}`, { x: 401, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal52}`, { x: 427, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal62}`, { x: 455, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal72}`, { x: 481, y: 450, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.cal82}`, { x: 511, y: 450, size: fontSize - 3, font: customFont, color })

        // BOP
        page.drawText(`${data.p4table2.bop81}`, { x: 106, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop71}`, { x: 132, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop61}`, { x: 161, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop51}`, { x: 190, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop41}`, { x: 220, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop31}`, { x: 248, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop21}`, { x: 272, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop11}`, { x: 298, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop12}`, { x: 324, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop22}`, { x: 350, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop32}`, { x: 377, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop42}`, { x: 401, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop52}`, { x: 427, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop62}`, { x: 455, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop72}`, { x: 481, y: 439, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table2.bop82}`, { x: 511, y: 439, size: fontSize - 3, font: customFont, color })
      }

      // p4table3
      if (data.p4table3) {
        // lunglay
        page.drawText(`${data.p4table3.lunglay81}`, { x: 106, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay71}`, { x: 132, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay61}`, { x: 161, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay51}`, { x: 190, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay41}`, { x: 220, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay31}`, { x: 248, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay21}`, { x: 272, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay11}`, { x: 298, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay12}`, { x: 324, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay22}`, { x: 350, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay32}`, { x: 377, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay42}`, { x: 401, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay52}`, { x: 427, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay62}`, { x: 455, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay72}`, { x: 481, y: 384, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.lunglay82}`, { x: 511, y: 384, size: fontSize - 3, font: customFont, color })

        // pii
        page.drawText(`${data.p4table3.pii81}`, { x: 106, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii71}`, { x: 132, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii61}`, { x: 161, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii51}`, { x: 190, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii41}`, { x: 220, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii31}`, { x: 248, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii21}`, { x: 272, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii11}`, { x: 298, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii12}`, { x: 324, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii22}`, { x: 350, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii32}`, { x: 377, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii42}`, { x: 401, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii52}`, { x: 427, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii62}`, { x: 455, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii72}`, { x: 481, y: 362.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.pii82}`, { x: 511, y: 362.5, size: fontSize - 3, font: customFont, color })

        // gi
        page.drawText(`${data.p4table3.gi81}`, { x: 106, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi71}`, { x: 132, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi61}`, { x: 161, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi51}`, { x: 190, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi41}`, { x: 220, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi31}`, { x: 248, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi21}`, { x: 272, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi11}`, { x: 298, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi12}`, { x: 324, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi22}`, { x: 350, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi32}`, { x: 377, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi42}`, { x: 401, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi52}`, { x: 427, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi62}`, { x: 455, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi72}`, { x: 481, y: 352, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gi82}`, { x: 511, y: 352, size: fontSize - 3, font: customFont, color })

        // ppd
        page.drawText(`${data.p4table3.ppd81}`, { x: 106, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd71}`, { x: 132, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd61}`, { x: 161, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd51}`, { x: 190, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd41}`, { x: 220, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd31}`, { x: 248, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd21}`, { x: 272, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd11}`, { x: 298, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd12}`, { x: 324, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd22}`, { x: 350, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd32}`, { x: 377, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd42}`, { x: 401, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd52}`, { x: 427, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd62}`, { x: 455, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd72}`, { x: 481, y: 340, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.ppd82}`, { x: 511, y: 340, size: fontSize - 3, font: customFont, color })

        // GM
        page.drawText(`${data.p4table3.gm81}`, { x: 106, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm71}`, { x: 132, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm61}`, { x: 161, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm51}`, { x: 190, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm41}`, { x: 220, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm31}`, { x: 248, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm21}`, { x: 272, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm11}`, { x: 298, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm12}`, { x: 324, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm22}`, { x: 350, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm32}`, { x: 377, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm42}`, { x: 401, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm52}`, { x: 427, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm62}`, { x: 455, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm72}`, { x: 481, y: 329, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.gm82}`, { x: 511, y: 329, size: fontSize - 3, font: customFont, color })

        // CAL
        page.drawText(`${data.p4table3.cal81}`, { x: 106, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal71}`, { x: 132, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal61}`, { x: 161, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal51}`, { x: 190, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal41}`, { x: 220, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal31}`, { x: 248, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal21}`, { x: 272, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal11}`, { x: 298, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal12}`, { x: 324, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal22}`, { x: 350, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal32}`, { x: 377, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal42}`, { x: 401, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal52}`, { x: 427, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal62}`, { x: 455, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal72}`, { x: 481, y: 318.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.cal82}`, { x: 511, y: 318.5, size: fontSize - 3, font: customFont, color })

        // BOP
        page.drawText(`${data.p4table3.bop81}`, { x: 106, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop71}`, { x: 132, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop61}`, { x: 161, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop51}`, { x: 190, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop41}`, { x: 220, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop31}`, { x: 248, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop21}`, { x: 272, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop11}`, { x: 298, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop12}`, { x: 324, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop22}`, { x: 350, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop32}`, { x: 377, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop42}`, { x: 401, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop52}`, { x: 427, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop62}`, { x: 455, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop72}`, { x: 481, y: 306, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table3.bop82}`, { x: 511, y: 306, size: fontSize - 3, font: customFont, color })
      }

      // p4table4
      if (data.p4table4) {
        // lunglay
        page.drawText(`${data.p4table4.lunglay81}`, { x: 106, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay71}`, { x: 132, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay61}`, { x: 161, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay51}`, { x: 190, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay41}`, { x: 220, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay31}`, { x: 248, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay21}`, { x: 272, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay11}`, { x: 298, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay12}`, { x: 324, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay22}`, { x: 350, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay32}`, { x: 377, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay42}`, { x: 401, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay52}`, { x: 427, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay62}`, { x: 455, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay72}`, { x: 481, y: 62, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.lunglay82}`, { x: 511, y: 62, size: fontSize - 3, font: customFont, color })

        // pii
        page.drawText(`${data.p4table4.pii81}`, { x: 106, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii71}`, { x: 132, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii61}`, { x: 161, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii51}`, { x: 190, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii41}`, { x: 220, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii31}`, { x: 248, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii21}`, { x: 272, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii11}`, { x: 298, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii12}`, { x: 324, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii22}`, { x: 350, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii32}`, { x: 377, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii42}`, { x: 401, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii52}`, { x: 427, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii62}`, { x: 455, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii72}`, { x: 481, y: 140, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.pii82}`, { x: 511, y: 140, size: fontSize - 3, font: customFont, color })

        // gi
        page.drawText(`${data.p4table4.gi81}`, { x: 106, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi71}`, { x: 132, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi61}`, { x: 161, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi51}`, { x: 190, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi41}`, { x: 220, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi31}`, { x: 248, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi21}`, { x: 272, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi11}`, { x: 298, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi12}`, { x: 324, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi22}`, { x: 350, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi32}`, { x: 377, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi42}`, { x: 401, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi52}`, { x: 427, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi62}`, { x: 455, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi72}`, { x: 481, y: 129, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gi82}`, { x: 511, y: 129, size: fontSize - 3, font: customFont, color })

        // ppd
        page.drawText(`${data.p4table4.ppd81}`, { x: 106, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd71}`, { x: 132, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd61}`, { x: 161, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd51}`, { x: 190, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd41}`, { x: 220, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd31}`, { x: 248, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd21}`, { x: 272, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd11}`, { x: 298, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd12}`, { x: 324, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd22}`, { x: 350, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd32}`, { x: 377, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd42}`, { x: 401, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd52}`, { x: 427, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd62}`, { x: 455, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd72}`, { x: 481, y: 118, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.ppd82}`, { x: 511, y: 118, size: fontSize - 3, font: customFont, color })

        // GM
        page.drawText(`${data.p4table4.gm81}`, { x: 106, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm71}`, { x: 132, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm61}`, { x: 161, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm51}`, { x: 190, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm41}`, { x: 220, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm31}`, { x: 248, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm21}`, { x: 272, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm11}`, { x: 298, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm12}`, { x: 324, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm22}`, { x: 350, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm32}`, { x: 377, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm42}`, { x: 401, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm52}`, { x: 427, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm62}`, { x: 455, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm72}`, { x: 481, y: 107, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.gm82}`, { x: 511, y: 107, size: fontSize - 3, font: customFont, color })

        // CAL
        page.drawText(`${data.p4table4.cal81}`, { x: 106, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal71}`, { x: 132, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal61}`, { x: 161, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal51}`, { x: 190, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal41}`, { x: 220, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal31}`, { x: 248, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal21}`, { x: 272, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal11}`, { x: 298, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal12}`, { x: 324, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal22}`, { x: 350, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal32}`, { x: 377, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal42}`, { x: 401, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal52}`, { x: 427, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal62}`, { x: 455, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal72}`, { x: 481, y: 94.5, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.cal82}`, { x: 511, y: 94.5, size: fontSize - 3, font: customFont, color })

        // BOP
        page.drawText(`${data.p4table4.bop81}`, { x: 106, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop71}`, { x: 132, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop61}`, { x: 161, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop51}`, { x: 190, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop41}`, { x: 220, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop31}`, { x: 248, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop21}`, { x: 272, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop11}`, { x: 298, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop12}`, { x: 324, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop22}`, { x: 350, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop32}`, { x: 377, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop42}`, { x: 401, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop52}`, { x: 427, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop62}`, { x: 455, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop72}`, { x: 481, y: 85, size: fontSize - 3, font: customFont, color })
        page.drawText(`${data.p4table4.bop82}`, { x: 511, y: 85, size: fontSize - 3, font: customFont, color })
      }
    } else if (index === 4) {
      page.drawText(data.tomtat, { x: 67, y: 731, size: fontSize, font: customFont, color })
      page.drawText(data.chandoan1, { x: 77, y: 365, size: fontSize, font: customFont, color })
      page.drawText(data.chandoan2, { x: 77, y: 297, size: fontSize, font: customFont, color })
      page.drawText(data.chandoan3, { x: 77, y: 212, size: fontSize, font: customFont, color })
      page.drawText(data.chandoan4, { x: 77, y: 162, size: fontSize, font: customFont, color })
      page.drawText(data.chandoan5, { x: 77, y: 94, size: fontSize, font: customFont, color })
    } else if (index === 5) {
      page.drawText(`${data.student}`, { x: 412, y: 747, size: fontSize + 1, font: customFont, color })
      page.drawText(`${data.code}`, { x: 374, y: 733, size: fontSize - 1, font: customFont, color })

      // row 1
      if (data.row1) {
        page.drawText(`${data.row1.luuy}`, { x: 64, y: 671, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row1.xutri}`, { x: 378, y: 671, size: fontSize - 1, font: customFont, color })
      }

      // row 2
      if (data.row2) {
        page.drawText(`${data.row2.luuy}`, { x: 64, y: 656, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row2.xutri}`, { x: 378, y: 656, size: fontSize - 1, font: customFont, color })
      }

      // row 3
      if (data.row3) {
        page.drawText(`${data.row3.luuy}`, { x: 64, y: 641, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row3.xutri}`, { x: 378, y: 641, size: fontSize - 1, font: customFont, color })
      }

      // row 4
      if (data.row4) {
        page.drawText(`${data.row4.loai}`, { x: 64, y: 585, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row4.gia}`, { x: 395, y: 585, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row4.sobuoi}`, { x: 515, y: 585, size: fontSize - 1, font: customFont, color })
      }

      // row 5
      if (data.row5) {
        page.drawText(`${data.row5.loai}`, { x: 64, y: 570, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row5.gia}`, { x: 395, y: 570, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row5.sobuoi}`, { x: 515, y: 570, size: fontSize - 1, font: customFont, color })
      }

      // row 6
      if (data.row6) {
        page.drawText(`${data.row6.loai}`, { x: 64, y: 540, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row6.gia}`, { x: 395, y: 540, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row6.sobuoi}`, { x: 515, y: 540, size: fontSize - 1, font: customFont, color })
      }

      // row 7
      if (data.row7) {
        page.drawText(`${data.row7.loai}`, { x: 64, y: 525, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row7.gia}`, { x: 395, y: 525, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row7.sobuoi}`, { x: 515, y: 525, size: fontSize - 1, font: customFont, color })
      }

      // row 8
      if (data.row8) {
        page.drawText(`${data.row8.loai}`, { x: 64, y: 510, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row8.gia}`, { x: 395, y: 510, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row8.sobuoi}`, { x: 515, y: 510, size: fontSize - 1, font: customFont, color })
      }

      // row 9
      if (data.row9) {
        page.drawText(`${data.row9.loai}`, { x: 64, y: 495, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row9.gia}`, { x: 395, y: 495, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row9.sobuoi}`, { x: 515, y: 495, size: fontSize - 1, font: customFont, color })
      }

      // row 10
      if (data.row10) {
        page.drawText(`${data.row10.loai}`, { x: 64, y: 467, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row10.gia}`, { x: 395, y: 467, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row10.sobuoi}`, { x: 515, y: 467, size: fontSize - 1, font: customFont, color })
      }

      // row 11
      if (data.row11) {
        page.drawText(`${data.row11.loai}`, { x: 64, y: 454, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row11.gia}`, { x: 395, y: 454, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row11.sobuoi}`, { x: 515, y: 454, size: fontSize - 1, font: customFont, color })
      }

      // row 12
      if (data.row12) {
        page.drawText(`${data.row12.loai}`, { x: 64, y: 441, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row12.gia}`, { x: 395, y: 441, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row12.sobuoi}`, { x: 515, y: 441, size: fontSize - 1, font: customFont, color })
      }

      // row 13
      if (data.row13) {
        page.drawText(`${data.row13.loai}`, { x: 64, y: 428, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row13.gia}`, { x: 395, y: 428, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row13.sobuoi}`, { x: 515, y: 428, size: fontSize - 1, font: customFont, color })
      }

      // row 14
      if (data.row14) {
        page.drawText(`${data.row14.loai}`, { x: 64, y: 415, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row14.gia}`, { x: 395, y: 415, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row14.sobuoi}`, { x: 515, y: 415, size: fontSize - 1, font: customFont, color })
      }

      // row 15
      if (data.row15) {
        page.drawText(`${data.row15.loai}`, { x: 64, y: 402, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row15.gia}`, { x: 395, y: 402, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row15.sobuoi}`, { x: 515, y: 402, size: fontSize - 1, font: customFont, color })
      }

      // row 16
      if (data.row16) {
        page.drawText(`${data.row16.loai}`, { x: 64, y: 389, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row16.gia}`, { x: 395, y: 389, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row16.sobuoi}`, { x: 515, y: 389, size: fontSize - 1, font: customFont, color })
      }

      // row 17
      if (data.row17) {
        page.drawText(`${data.row17.loai}`, { x: 64, y: 362, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row17.gia}`, { x: 395, y: 362, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row17.sobuoi}`, { x: 515, y: 362, size: fontSize - 1, font: customFont, color })
      }

      // row 18
      if (data.row18) {
        page.drawText(`${data.row18.loai}`, { x: 64, y: 349, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row18.gia}`, { x: 395, y: 349, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row18.sobuoi}`, { x: 515, y: 349, size: fontSize - 1, font: customFont, color })
      }

      // row 19
      if (data.row19) {
        page.drawText(`${data.row19.loai}`, { x: 64, y: 336, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row19.gia}`, { x: 395, y: 336, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.row19.sobuoi}`, { x: 515, y: 336, size: fontSize - 1, font: customFont, color })
      }

      // rowtotal
      if (data.rowtotal) {
        page.drawText(`${data.rowtotal.gia}`, { x: 395, y: 291, size: fontSize - 1, font: customFont, color })
        page.drawText(`${data.rowtotal.sobuoi}`, { x: 515, y: 291, size: fontSize - 1, font: customFont, color })
      }

      if (signature) {
        page.drawImage(image, {
          x: 393,
          y: 50,
          height: imageDims.height,
          width: imageDims.width
        })
      }
      if (headName) {
        page.drawText(headName, { x: 400, y: 43, size: fontSize, font: customFont, color })
      }
    } else if (index === 6) {
      if (data.ques1) {
        if (data.ques1 === 1) {
          page.drawText('X', { x: 101, y: 661, size: fontSize + 8, font: customFont, color })
        } else if (data.ques1 === 2) {
          page.drawText('X', { x: 191, y: 661, size: fontSize + 8, font: customFont, color })
        } else if (data.ques1 === 3) {
          page.drawText('X', { x: 281, y: 661, size: fontSize + 8, font: customFont, color })
        } else if (data.ques1 === 4) {
          page.drawText('X', { x: 371, y: 661, size: fontSize + 8, font: customFont, color })
        } else if (data.ques1 === 5) {
          page.drawText('X', { x: 461, y: 661, size: fontSize + 8, font: customFont, color })
        }
      }
      if (data.ques2) {
        if (data.ques2 === 1) {
          page.drawText('X', { x: 101, y: 610, size: fontSize + 8, font: customFont, color })
        } else if (data.ques2 === 2) {
          page.drawText('X', { x: 191, y: 610, size: fontSize + 8, font: customFont, color })
        } else if (data.ques2 === 3) {
          page.drawText('X', { x: 281, y: 610, size: fontSize + 8, font: customFont, color })
        } else if (data.ques2 === 4) {
          page.drawText('X', { x: 371, y: 610, size: fontSize + 8, font: customFont, color })
        } else if (data.ques2 === 5) {
          page.drawText('X', { x: 461, y: 610, size: fontSize + 8, font: customFont, color })
        }
      }
      page.drawText(data.ques3, { x: 341, y: 583, size: fontSize, font: customFont, color })
      if (data.ques4 === 'yes') {
        page.drawText('X', { x: 392, y: 564, size: fontSize, font: customFont, color })
      } else {
        page.drawText('X', { x: 455, y: 564, size: fontSize, font: customFont, color })
      }
      page.drawText(data.ques4, { x: 341, y: 549, size: fontSize, font: customFont, color })

      if (data.ques5) {
        if (data.ques5 === 1) {
          page.drawText('X', { x: 101, y: 526, size: fontSize + 8, font: customFont, color })
        } else if (data.ques5 === 2) {
          page.drawText('X', { x: 191, y: 526, size: fontSize + 8, font: customFont, color })
        } else if (data.ques5 === 3) {
          page.drawText('X', { x: 281, y: 526, size: fontSize + 8, font: customFont, color })
        } else if (data.ques5 === 4) {
          page.drawText('X', { x: 371, y: 526, size: fontSize + 8, font: customFont, color })
        } else if (data.ques5 === 5) {
          page.drawText('X', { x: 461, y: 526, size: fontSize + 8, font: customFont, color })
        }
      }

      page.drawText(data.ques6, { x: 175, y: 496, size: fontSize, font: customFont, color })
      page.drawText(data.ques7, { x: 71, y: 465, size: fontSize, font: customFont, color })
      page.drawText(data.datepage7first, { x: 375, y: 446, size: fontSize, font: customFont, color })

      if (data.ques21) {
        if (data.ques21 === 1) {
          page.drawText('X', { x: 129, y: 381, size: fontSize + 8, font: customFont, color })
        } else if (data.ques21 === 2) {
          page.drawText('X', { x: 215, y: 381, size: fontSize + 8, font: customFont, color })
        } else if (data.ques21 === 3) {
          page.drawText('X', { x: 300, y: 381, size: fontSize + 8, font: customFont, color })
        } else if (data.ques21 === 4) {
          page.drawText('X', { x: 385, y: 381, size: fontSize + 8, font: customFont, color })
        } else if (data.ques21 === 5) {
          page.drawText('X', { x: 470.5, y: 381, size: fontSize + 8, font: customFont, color })
        }
      }
      if (data.ques22) {
        if (data.ques22 === 1) {
          page.drawText('X', { x: 129, y: 326.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques22 === 2) {
          page.drawText('X', { x: 215, y: 326.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques22 === 3) {
          page.drawText('X', { x: 300, y: 326.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques22 === 4) {
          page.drawText('X', { x: 385, y: 326.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques22 === 5) {
          page.drawText('X', { x: 470.5, y: 326.5, size: fontSize + 8, font: customFont, color })
        }
      }
      if (data.ques23) {
        if (data.ques23 === 1) {
          page.drawText('X', { x: 129, y: 271.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques23 === 2) {
          page.drawText('X', { x: 215, y: 271.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques23 === 3) {
          page.drawText('X', { x: 300, y: 271.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques23 === 4) {
          page.drawText('X', { x: 385, y: 271.5, size: fontSize + 8, font: customFont, color })
        } else if (data.ques23 === 5) {
          page.drawText('X', { x: 470.5, y: 271.5, size: fontSize + 8, font: customFont, color })
        }
      }
      if (data.ques24) {
        if (data.ques24 === 1) {
          page.drawText('X', { x: 129, y: 217, size: fontSize + 8, font: customFont, color })
        } else if (data.ques24 === 2) {
          page.drawText('X', { x: 215, y: 217, size: fontSize + 8, font: customFont, color })
        } else if (data.ques24 === 3) {
          page.drawText('X', { x: 300, y: 217, size: fontSize + 8, font: customFont, color })
        } else if (data.ques24 === 4) {
          page.drawText('X', { x: 385, y: 217, size: fontSize + 8, font: customFont, color })
        } else if (data.ques24 === 5) {
          page.drawText('X', { x: 470.5, y: 217, size: fontSize + 8, font: customFont, color })
        }
      }

      if (data.ques25 === 'yes') {
        page.drawText('X', { x: 411, y: 185, size: fontSize, font: customFont, color })
      } else {
        page.drawText('X', { x: 462, y: 185, size: fontSize, font: customFont, color })
      }

      if (data.ques26) {
        if (data.ques26 === 1) {
          page.drawText('X', { x: 115, y: 147, size: fontSize + 8, font: customFont, color })
        } else if (data.ques26 === 2) {
          page.drawText('X', { x: 205, y: 147, size: fontSize + 8, font: customFont, color })
        } else if (data.ques26 === 3) {
          page.drawText('X', { x: 295, y: 147, size: fontSize + 8, font: customFont, color })
        } else if (data.ques26 === 4) {
          page.drawText('X', { x: 385, y: 147, size: fontSize + 8, font: customFont, color })
        } else if (data.ques26 === 5) {
          page.drawText('X', { x: 475, y: 147, size: fontSize + 8, font: customFont, color })
        }
      }

      page.drawText(data.benhnhangopy, { x: 66, y: 103, size: fontSize, font: customFont, color })

      page.drawText(data.datepage7second, { x: 446, y: 88, size: fontSize, font: customFont, color })
    } else if (index === 7) {
      if (data.p8row1) {
        page.drawText(data.p8row1.rang, { x: 95, y: 677, size: fontSize, font: customFont, color })
        page.drawText(data.p8row1.noidung, { x: 159, y: 677, size: fontSize, font: customFont, color })
        page.drawText(data.p8row1.svdieutri, { x: 405, y: 677, size: fontSize, font: customFont, color })
        page.drawText(data.p8row1.svtrothu, { x: 461, y: 677, size: fontSize, font: customFont, color })
        page.drawText(data.p8row1.mentor, { x: 513, y: 677, size: fontSize, font: customFont, color })
        page.drawText(data.p8row1.note, { x: 124, y: 658, size: fontSize, font: customFont, color })
        if (signature && data.p8row1.rang) {
          page.drawImage(image, {
            x: 560,
            y: 652,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row2) {
        page.drawText(data.p8row2.rang, { x: 95, y: 637, size: fontSize, font: customFont, color })
        page.drawText(data.p8row2.noidung, { x: 159, y: 637, size: fontSize, font: customFont, color })
        page.drawText(data.p8row2.svdieutri, { x: 405, y: 637, size: fontSize, font: customFont, color })
        page.drawText(data.p8row2.svtrothu, { x: 461, y: 637, size: fontSize, font: customFont, color })
        page.drawText(data.p8row2.mentor, { x: 513, y: 637, size: fontSize, font: customFont, color })
        page.drawText(data.p8row2.note, { x: 124, y: 617, size: fontSize, font: customFont, color })
        if (signature && data.p8row2.rang) {
          page.drawImage(image, {
            x: 560,
            y: 613,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row3) {
        page.drawText(data.p8row3.rang, { x: 95, y: 595, size: fontSize, font: customFont, color })
        page.drawText(data.p8row3.noidung, { x: 159, y: 595, size: fontSize, font: customFont, color })
        page.drawText(data.p8row3.svdieutri, { x: 405, y: 595, size: fontSize, font: customFont, color })
        page.drawText(data.p8row3.svtrothu, { x: 461, y: 595, size: fontSize, font: customFont, color })
        page.drawText(data.p8row3.mentor, { x: 513, y: 595, size: fontSize, font: customFont, color })
        page.drawText(data.p8row3.note, { x: 124, y: 577, size: fontSize, font: customFont, color })
        if (signature && data.p8row3.rang) {
          page.drawImage(image, {
            x: 560,
            y: 571,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row4) {
        page.drawText(data.p8row4.rang, { x: 95, y: 555, size: fontSize, font: customFont, color })
        page.drawText(data.p8row4.noidung, { x: 159, y: 555, size: fontSize, font: customFont, color })
        page.drawText(data.p8row4.svdieutri, { x: 405, y: 555, size: fontSize, font: customFont, color })
        page.drawText(data.p8row4.svtrothu, { x: 461, y: 555, size: fontSize, font: customFont, color })
        page.drawText(data.p8row4.mentor, { x: 513, y: 555, size: fontSize, font: customFont, color })
        page.drawText(data.p8row4.note, { x: 124, y: 535, size: fontSize, font: customFont, color })
        if (signature && data.p8row4.rang) {
          page.drawImage(image, {
            x: 560,
            y: 529,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row5) {
        page.drawText(data.p8row5.rang, { x: 95, y: 514, size: fontSize, font: customFont, color })
        page.drawText(data.p8row5.noidung, { x: 159, y: 515, size: fontSize, font: customFont, color })
        page.drawText(data.p8row5.svdieutri, { x: 405, y: 515, size: fontSize, font: customFont, color })
        page.drawText(data.p8row5.svtrothu, { x: 461, y: 515, size: fontSize, font: customFont, color })
        page.drawText(data.p8row5.mentor, { x: 513, y: 515, size: fontSize, font: customFont, color })
        page.drawText(data.p8row5.note, { x: 124, y: 498, size: fontSize, font: customFont, color })
        if (signature && data.p8row5.rang) {
          page.drawImage(image, {
            x: 560,
            y: 492,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row6) {
        page.drawText(data.p8row6.rang, { x: 95, y: 475, size: fontSize, font: customFont, color })
        page.drawText(data.p8row6.noidung, { x: 159, y: 475, size: fontSize, font: customFont, color })
        page.drawText(data.p8row6.svdieutri, { x: 405, y: 475, size: fontSize, font: customFont, color })
        page.drawText(data.p8row6.svtrothu, { x: 461, y: 475, size: fontSize, font: customFont, color })
        page.drawText(data.p8row6.mentor, { x: 513, y: 475, size: fontSize, font: customFont, color })
        page.drawText(data.p8row6.note, { x: 124, y: 457, size: fontSize, font: customFont, color })
        if (signature && data.p8row6.rang) {
          page.drawImage(image, {
            x: 560,
            y: 451,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row7) {
        page.drawText(data.p8row7.rang, { x: 95, y: 437, size: fontSize, font: customFont, color })
        page.drawText(data.p8row7.noidung, { x: 159, y: 437, size: fontSize, font: customFont, color })
        page.drawText(data.p8row7.svdieutri, { x: 405, y: 437, size: fontSize, font: customFont, color })
        page.drawText(data.p8row7.svtrothu, { x: 461, y: 437, size: fontSize, font: customFont, color })
        page.drawText(data.p8row7.mentor, { x: 513, y: 437, size: fontSize, font: customFont, color })
        page.drawText(data.p8row7.note, { x: 124, y: 419, size: fontSize, font: customFont, color })
        if (signature && data.p8row7.rang) {
          page.drawImage(image, {
            x: 560,
            y: 415,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row8) {
        page.drawText(data.p8row8.rang, { x: 95, y: 402, size: fontSize, font: customFont, color })
        page.drawText(data.p8row8.noidung, { x: 159, y: 402, size: fontSize, font: customFont, color })
        page.drawText(data.p8row8.svdieutri, { x: 405, y: 402, size: fontSize, font: customFont, color })
        page.drawText(data.p8row8.svtrothu, { x: 461, y: 402, size: fontSize, font: customFont, color })
        page.drawText(data.p8row8.mentor, { x: 513, y: 402, size: fontSize, font: customFont, color })
        page.drawText(data.p8row8.note, { x: 124, y: 385, size: fontSize, font: customFont, color })
        if (signature && data.p8row8.rang) {
          page.drawImage(image, {
            x: 560,
            y: 379,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row9) {
        page.drawText(data.p8row9.rang, { x: 95, y: 365, size: fontSize, font: customFont, color })
        page.drawText(data.p8row9.noidung, { x: 159, y: 365, size: fontSize, font: customFont, color })
        page.drawText(data.p8row9.svdieutri, { x: 405, y: 365, size: fontSize, font: customFont, color })
        page.drawText(data.p8row9.svtrothu, { x: 461, y: 365, size: fontSize, font: customFont, color })
        page.drawText(data.p8row9.mentor, { x: 513, y: 365, size: fontSize, font: customFont, color })
        page.drawText(data.p8row9.note, { x: 124, y: 348, size: fontSize, font: customFont, color })
        if (signature && data.p8row9.rang) {
          page.drawImage(image, {
            x: 560,
            y: 342,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row10) {
        page.drawText(data.p8row10.rang, { x: 95, y: 328, size: fontSize, font: customFont, color })
        page.drawText(data.p8row10.noidung, { x: 159, y: 328, size: fontSize, font: customFont, color })
        page.drawText(data.p8row10.svdieutri, { x: 405, y: 328, size: fontSize, font: customFont, color })
        page.drawText(data.p8row10.svtrothu, { x: 461, y: 328, size: fontSize, font: customFont, color })
        page.drawText(data.p8row10.mentor, { x: 513, y: 328, size: fontSize, font: customFont, color })
        page.drawText(data.p8row10.note, { x: 124, y: 310, size: fontSize, font: customFont, color })
        if (signature && data.p8row10.rang) {
          page.drawImage(image, {
            x: 560,
            y: 304,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row11) {
        page.drawText(data.p8row11.rang, { x: 95, y: 290, size: fontSize, font: customFont, color })
        page.drawText(data.p8row11.noidung, { x: 159, y: 290, size: fontSize, font: customFont, color })
        page.drawText(data.p8row11.svdieutri, { x: 405, y: 290, size: fontSize, font: customFont, color })
        page.drawText(data.p8row11.svtrothu, { x: 461, y: 290, size: fontSize, font: customFont, color })
        page.drawText(data.p8row11.mentor, { x: 513, y: 290, size: fontSize, font: customFont, color })
        page.drawText(data.p8row11.note, { x: 124, y: 273, size: fontSize, font: customFont, color })
        if (signature && data.p8row11.rang) {
          page.drawImage(image, {
            x: 560,
            y: 267,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row12) {
        page.drawText(data.p8row12.rang, { x: 95, y: 252, size: fontSize, font: customFont, color })
        page.drawText(data.p8row12.noidung, { x: 159, y: 252, size: fontSize, font: customFont, color })
        page.drawText(data.p8row12.svdieutri, { x: 405, y: 252, size: fontSize, font: customFont, color })
        page.drawText(data.p8row12.svtrothu, { x: 461, y: 252, size: fontSize, font: customFont, color })
        page.drawText(data.p8row12.mentor, { x: 513, y: 252, size: fontSize, font: customFont, color })
        page.drawText(data.p8row12.note, { x: 124, y: 235, size: fontSize, font: customFont, color })
        if (signature && data.p8row12.rang) {
          page.drawImage(image, {
            x: 560,
            y: 229,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row13) {
        page.drawText(data.p8row13.rang, { x: 95, y: 214, size: fontSize, font: customFont, color })
        page.drawText(data.p8row13.noidung, { x: 159, y: 214, size: fontSize, font: customFont, color })
        page.drawText(data.p8row13.svdieutri, { x: 405, y: 214, size: fontSize, font: customFont, color })
        page.drawText(data.p8row13.svtrothu, { x: 461, y: 214, size: fontSize, font: customFont, color })
        page.drawText(data.p8row13.mentor, { x: 513, y: 214, size: fontSize, font: customFont, color })
        page.drawText(data.p8row13.note, { x: 124, y: 197, size: fontSize, font: customFont, color })
        if (signature && data.p8row13.rang) {
          page.drawImage(image, {
            x: 560,
            y: 191,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row14) {
        page.drawText(data.p8row14.rang, { x: 95, y: 177, size: fontSize, font: customFont, color })
        page.drawText(data.p8row14.noidung, { x: 159, y: 177, size: fontSize, font: customFont, color })
        page.drawText(data.p8row14.svdieutri, { x: 405, y: 177, size: fontSize, font: customFont, color })
        page.drawText(data.p8row14.svtrothu, { x: 461, y: 177, size: fontSize, font: customFont, color })
        page.drawText(data.p8row14.mentor, { x: 513, y: 177, size: fontSize, font: customFont, color })
        page.drawText(data.p8row14.note, { x: 124, y: 159, size: fontSize, font: customFont, color })
        if (signature && data.p8row14.rang) {
          page.drawImage(image, {
            x: 560,
            y: 155,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }

      if (data.p8row15) {
        page.drawText(data.p8row15.rang, { x: 95, y: 139, size: fontSize, font: customFont, color })
        page.drawText(data.p8row15.noidung, { x: 159, y: 139, size: fontSize, font: customFont, color })
        page.drawText(data.p8row15.svdieutri, { x: 405, y: 139, size: fontSize, font: customFont, color })
        page.drawText(data.p8row15.svtrothu, { x: 461, y: 139, size: fontSize, font: customFont, color })
        page.drawText(data.p8row15.mentor, { x: 513, y: 139, size: fontSize, font: customFont, color })
        page.drawText(data.p8row15.note, { x: 124, y: 122, size: fontSize, font: customFont, color })
        if (signature && data.p8row15.rang) {
          page.drawImage(image, {
            x: 560,
            y: 116,
            height: imageDims.height / 2,
            width: imageDims.width / 2
          })
        }
      }
    } else if (index === 8) {
      page.drawText(data.page9.row1col1, { x: 106, y: 676, size: fontSize, font: customFont, color })
      page.drawText(data.page9.row1col2, { x: 220, y: 676, size: fontSize, font: customFont, color })
      page.drawText(data.page9.row1col3, { x: 320, y: 676, size: fontSize, font: customFont, color })
      page.drawText(data.page9.row1col4, { x: 458, y: 676, size: fontSize, font: customFont, color })

      page.drawText(data.page9.row2col1, { x: 102, y: 606, size: fontSize, font: customFont, color })
      page.drawText(data.page9.row2col2, { x: 214, y: 606, size: fontSize, font: customFont, color })
      page.drawText(data.page9.row2col3, { x: 320, y: 606, size: fontSize, font: customFont, color })
      page.drawText(data.page9.row2col4, { x: 458, y: 606, size: fontSize, font: customFont, color })

      page.drawText(data.page9.note1, { x: 73, y: 514, size: fontSize, font: customFont, color })
      page.drawText(data.page9.note2, { x: 73, y: 422.5, size: fontSize, font: customFont, color })
      page.drawText(data.page9.note3, { x: 73, y: 328, size: fontSize, font: customFont, color })
      page.drawText(data.page9.note4, { x: 73, y: 251.5, size: fontSize, font: customFont, color })
    } else if (index === 9) {
      // giaotiep
      // row1
      if (data.giaotiep.row1) {
        if (data.giaotiep.row1 === '1') {
          page.drawText('X', { x: 434, y: 677, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row1 === '2') {
          page.drawText('X', { x: 459, y: 677, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row1 === '3') {
          page.drawText('X', { x: 483, y: 677, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row1 === '4') {
          page.drawText('X', { x: 508, y: 677, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row1 === '5') {
          page.drawText('X', { x: 533, y: 677, size: fontSize, font: customFont, color })
        }
      }

      // row2
      if (data.giaotiep.row2) {
        if (data.giaotiep.row2 === '1') {
          page.drawText('X', { x: 434, y: 663, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row2 === '2') {
          page.drawText('X', { x: 459, y: 663, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row2 === '3') {
          page.drawText('X', { x: 483, y: 663, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row2 === '4') {
          page.drawText('X', { x: 508, y: 663, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row2 === '5') {
          page.drawText('X', { x: 533, y: 663, size: fontSize, font: customFont, color })
        }
      }

      // row3
      if (data.giaotiep.row3) {
        if (data.giaotiep.row3 === '1') {
          page.drawText('X', { x: 434, y: 648, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row3 === '2') {
          page.drawText('X', { x: 459, y: 648, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row3 === '3') {
          page.drawText('X', { x: 483, y: 648, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row3 === '4') {
          page.drawText('X', { x: 508, y: 648, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row3 === '5') {
          page.drawText('X', { x: 533, y: 648, size: fontSize, font: customFont, color })
        }
      }

      // row4
      if (data.giaotiep.row4) {
        if (data.giaotiep.row4 === '1') {
          page.drawText('X', { x: 434, y: 634, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row4 === '2') {
          page.drawText('X', { x: 459, y: 634, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row4 === '3') {
          page.drawText('X', { x: 483, y: 634, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row4 === '4') {
          page.drawText('X', { x: 508, y: 634, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row4 === '5') {
          page.drawText('X', { x: 533, y: 634, size: fontSize, font: customFont, color })
        }
      }

      // row5
      if (data.giaotiep.row5) {
        if (data.giaotiep.row5 === '1') {
          page.drawText('X', { x: 434, y: 620, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row5 === '2') {
          page.drawText('X', { x: 459, y: 620, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row5 === '3') {
          page.drawText('X', { x: 483, y: 620, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row5 === '4') {
          page.drawText('X', { x: 508, y: 620, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row5 === '5') {
          page.drawText('X', { x: 533, y: 620, size: fontSize, font: customFont, color })
        }
      }

      // row6
      if (data.giaotiep.row6) {
        if (data.giaotiep.row6 === '1') {
          page.drawText('X', { x: 434, y: 606, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row6 === '2') {
          page.drawText('X', { x: 459, y: 606, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row6 === '3') {
          page.drawText('X', { x: 483, y: 606, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row6 === '4') {
          page.drawText('X', { x: 508, y: 606, size: fontSize, font: customFont, color })
        } else if (data.giaotiep.row6 === '5') {
          page.drawText('X', { x: 533, y: 606, size: fontSize, font: customFont, color })
        }
      }

      page.drawText(data.giaotiep.note, { x: 155, y: 590, size: fontSize, font: customFont, color })

      // tontrong
      // row1
      if (data.tontrong.row1) {
        if (data.tontrong.row1 === '1') {
          page.drawText('X', { x: 434, y: 535, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row1 === '2') {
          page.drawText('X', { x: 459, y: 535, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row1 === '3') {
          page.drawText('X', { x: 483, y: 535, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row1 === '4') {
          page.drawText('X', { x: 508, y: 535, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row1 === '5') {
          page.drawText('X', { x: 533, y: 535, size: fontSize, font: customFont, color })
        }
      }

      // row2
      if (data.tontrong.row2) {
        if (data.tontrong.row2 === '1') {
          page.drawText('X', { x: 434, y: 520, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row2 === '2') {
          page.drawText('X', { x: 459, y: 520, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row2 === '3') {
          page.drawText('X', { x: 483, y: 520, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row2 === '4') {
          page.drawText('X', { x: 508, y: 520, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row2 === '5') {
          page.drawText('X', { x: 533, y: 520, size: fontSize, font: customFont, color })
        }
      }

      // row3
      if (data.tontrong.row3) {
        if (data.tontrong.row3 === '1') {
          page.drawText('X', { x: 434, y: 503, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row3 === '2') {
          page.drawText('X', { x: 459, y: 503, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row3 === '3') {
          page.drawText('X', { x: 483, y: 503, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row3 === '4') {
          page.drawText('X', { x: 508, y: 503, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row3 === '5') {
          page.drawText('X', { x: 533, y: 503, size: fontSize, font: customFont, color })
        }
      }

      // row4
      if (data.tontrong.row4) {
        if (data.tontrong.row4 === '1') {
          page.drawText('X', { x: 434, y: 487, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row4 === '2') {
          page.drawText('X', { x: 459, y: 487, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row4 === '3') {
          page.drawText('X', { x: 483, y: 487, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row4 === '4') {
          page.drawText('X', { x: 508, y: 487, size: fontSize, font: customFont, color })
        } else if (data.tontrong.row4 === '5') {
          page.drawText('X', { x: 533, y: 487, size: fontSize, font: customFont, color })
        }
      }

      page.drawText(data.tontrong.note, { x: 155, y: 470, size: fontSize, font: customFont, color })

      // daoduc
      // row1
      if (data.daoduc.row1) {
        if (data.daoduc.row1 === '1') {
          page.drawText('X', { x: 434, y: 419, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row1 === '2') {
          page.drawText('X', { x: 459, y: 419, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row1 === '3') {
          page.drawText('X', { x: 483, y: 419, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row1 === '4') {
          page.drawText('X', { x: 508, y: 419, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row1 === '5') {
          page.drawText('X', { x: 533, y: 419, size: fontSize, font: customFont, color })
        }
      }

      // row2
      if (data.daoduc.row2) {
        if (data.daoduc.row2 === '1') {
          page.drawText('X', { x: 434, y: 404, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row2 === '2') {
          page.drawText('X', { x: 459, y: 404, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row2 === '3') {
          page.drawText('X', { x: 483, y: 404, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row2 === '4') {
          page.drawText('X', { x: 508, y: 404, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row2 === '5') {
          page.drawText('X', { x: 533, y: 404, size: fontSize, font: customFont, color })
        }
      }

      // row3
      if (data.daoduc.row3) {
        if (data.daoduc.row3 === '1') {
          page.drawText('X', { x: 434, y: 390, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row3 === '2') {
          page.drawText('X', { x: 459, y: 390, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row3 === '3') {
          page.drawText('X', { x: 483, y: 390, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row3 === '4') {
          page.drawText('X', { x: 508, y: 390, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row3 === '5') {
          page.drawText('X', { x: 533, y: 390, size: fontSize, font: customFont, color })
        }
      }

      // row4
      if (data.daoduc.row4) {
        if (data.daoduc.row4 === '1') {
          page.drawText('X', { x: 434, y: 375, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row4 === '2') {
          page.drawText('X', { x: 459, y: 375, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row4 === '3') {
          page.drawText('X', { x: 483, y: 375, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row4 === '4') {
          page.drawText('X', { x: 508, y: 375, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row4 === '5') {
          page.drawText('X', { x: 533, y: 375, size: fontSize, font: customFont, color })
        }
      }

      // row5
      if (data.daoduc.row5) {
        if (data.daoduc.row5 === '1') {
          page.drawText('X', { x: 434, y: 362, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row5 === '2') {
          page.drawText('X', { x: 459, y: 362, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row5 === '3') {
          page.drawText('X', { x: 483, y: 362, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row5 === '4') {
          page.drawText('X', { x: 508, y: 362, size: fontSize, font: customFont, color })
        } else if (data.daoduc.row5 === '5') {
          page.drawText('X', { x: 533, y: 362, size: fontSize, font: customFont, color })
        }
      }

      page.drawText(data.daoduc.note, { x: 155, y: 347, size: fontSize, font: customFont, color })

      // thaido
      // row1
      if (data.thaido.row1) {
        if (data.thaido.row1 === '1') {
          page.drawText('X', { x: 434, y: 290, size: fontSize, font: customFont, color })
        } else if (data.thaido.row1 === '2') {
          page.drawText('X', { x: 459, y: 290, size: fontSize, font: customFont, color })
        } else if (data.thaido.row1 === '3') {
          page.drawText('X', { x: 483, y: 290, size: fontSize, font: customFont, color })
        } else if (data.thaido.row1 === '4') {
          page.drawText('X', { x: 508, y: 290, size: fontSize, font: customFont, color })
        } else if (data.thaido.row1 === '5') {
          page.drawText('X', { x: 533, y: 290, size: fontSize, font: customFont, color })
        }
      }

      // row2
      if (data.thaido.row2) {
        if (data.thaido.row2 === '1') {
          page.drawText('X', { x: 434, y: 269, size: fontSize, font: customFont, color })
        } else if (data.thaido.row2 === '2') {
          page.drawText('X', { x: 459, y: 269, size: fontSize, font: customFont, color })
        } else if (data.thaido.row2 === '3') {
          page.drawText('X', { x: 483, y: 269, size: fontSize, font: customFont, color })
        } else if (data.thaido.row2 === '4') {
          page.drawText('X', { x: 508, y: 269, size: fontSize, font: customFont, color })
        } else if (data.thaido.row2 === '5') {
          page.drawText('X', { x: 533, y: 269, size: fontSize, font: customFont, color })
        }
      }

      // row3
      if (data.thaido.row3) {
        if (data.thaido.row3 === '1') {
          page.drawText('X', { x: 434, y: 254, size: fontSize, font: customFont, color })
        } else if (data.thaido.row3 === '2') {
          page.drawText('X', { x: 459, y: 254, size: fontSize, font: customFont, color })
        } else if (data.thaido.row3 === '3') {
          page.drawText('X', { x: 483, y: 254, size: fontSize, font: customFont, color })
        } else if (data.thaido.row3 === '4') {
          page.drawText('X', { x: 508, y: 254, size: fontSize, font: customFont, color })
        } else if (data.thaido.row3 === '5') {
          page.drawText('X', { x: 533, y: 254, size: fontSize, font: customFont, color })
        }
      }

      // row4
      if (data.thaido.row4) {
        if (data.thaido.row4 === '1') {
          page.drawText('X', { x: 434, y: 240, size: fontSize, font: customFont, color })
        } else if (data.thaido.row4 === '2') {
          page.drawText('X', { x: 459, y: 240, size: fontSize, font: customFont, color })
        } else if (data.thaido.row4 === '3') {
          page.drawText('X', { x: 483, y: 240, size: fontSize, font: customFont, color })
        } else if (data.thaido.row4 === '4') {
          page.drawText('X', { x: 508, y: 240, size: fontSize, font: customFont, color })
        } else if (data.thaido.row4 === '5') {
          page.drawText('X', { x: 533, y: 240, size: fontSize, font: customFont, color })
        }
      }

      // row5
      if (data.thaido.row5) {
        if (data.thaido.row5 === '1') {
          page.drawText('X', { x: 434, y: 226, size: fontSize, font: customFont, color })
        } else if (data.thaido.row5 === '2') {
          page.drawText('X', { x: 459, y: 226, size: fontSize, font: customFont, color })
        } else if (data.thaido.row5 === '3') {
          page.drawText('X', { x: 483, y: 226, size: fontSize, font: customFont, color })
        } else if (data.thaido.row5 === '4') {
          page.drawText('X', { x: 508, y: 226, size: fontSize, font: customFont, color })
        } else if (data.thaido.row5 === '5') {
          page.drawText('X', { x: 533, y: 226, size: fontSize, font: customFont, color })
        }
      }

      // row6
      if (data.thaido.row6) {
        if (data.thaido.row6 === '1') {
          page.drawText('X', { x: 434, y: 212, size: fontSize, font: customFont, color })
        } else if (data.thaido.row6 === '2') {
          page.drawText('X', { x: 459, y: 212, size: fontSize, font: customFont, color })
        } else if (data.thaido.row6 === '3') {
          page.drawText('X', { x: 483, y: 212, size: fontSize, font: customFont, color })
        } else if (data.thaido.row6 === '4') {
          page.drawText('X', { x: 508, y: 212, size: fontSize, font: customFont, color })
        } else if (data.thaido.row6 === '5') {
          page.drawText('X', { x: 533, y: 212, size: fontSize, font: customFont, color })
        }
      }

      page.drawText(data.thaido.note, { x: 155, y: 197, size: fontSize, font: customFont, color })

      // chuanbi
      // row1
      if (data.chuanbi.row1) {
        if (data.chuanbi.row1 === '1') {
          page.drawText('X', { x: 434, y: 145, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row1 === '2') {
          page.drawText('X', { x: 459, y: 145, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row1 === '3') {
          page.drawText('X', { x: 483, y: 145, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row1 === '4') {
          page.drawText('X', { x: 508, y: 145, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row1 === '5') {
          page.drawText('X', { x: 533, y: 145, size: fontSize, font: customFont, color })
        }
      }

      // row2
      if (data.chuanbi.row2) {
        if (data.chuanbi.row2 === '1') {
          page.drawText('X', { x: 434, y: 131, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row2 === '2') {
          page.drawText('X', { x: 459, y: 131, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row2 === '3') {
          page.drawText('X', { x: 483, y: 131, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row2 === '4') {
          page.drawText('X', { x: 508, y: 131, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row2 === '5') {
          page.drawText('X', { x: 533, y: 131, size: fontSize, font: customFont, color })
        }
      }

      // row3
      if (data.chuanbi.row3) {
        if (data.chuanbi.row3 === '1') {
          page.drawText('X', { x: 434, y: 116, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row3 === '2') {
          page.drawText('X', { x: 459, y: 116, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row3 === '3') {
          page.drawText('X', { x: 483, y: 116, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row3 === '4') {
          page.drawText('X', { x: 508, y: 116, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row3 === '5') {
          page.drawText('X', { x: 533, y: 116, size: fontSize, font: customFont, color })
        }
      }

      // row4
      if (data.chuanbi.row4) {
        if (data.chuanbi.row4 === '1') {
          page.drawText('X', { x: 434, y: 102, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row4 === '2') {
          page.drawText('X', { x: 459, y: 102, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row4 === '3') {
          page.drawText('X', { x: 483, y: 102, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row4 === '4') {
          page.drawText('X', { x: 508, y: 102, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row4 === '5') {
          page.drawText('X', { x: 533, y: 102, size: fontSize, font: customFont, color })
        }
      }

      // row5
      if (data.chuanbi.row5) {
        if (data.chuanbi.row5 === '1') {
          page.drawText('X', { x: 434, y: 88, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row5 === '2') {
          page.drawText('X', { x: 459, y: 88, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row5 === '3') {
          page.drawText('X', { x: 483, y: 88, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row5 === '4') {
          page.drawText('X', { x: 508, y: 88, size: fontSize, font: customFont, color })
        } else if (data.chuanbi.row5 === '5') {
          page.drawText('X', { x: 533, y: 88, size: fontSize, font: customFont, color })
        }
      }

      page.drawText(data.chuanbi.note, { x: 155, y: 73, size: fontSize, font: customFont, color })
    }
  })

  const pdfBytes = await pdfDoc.save()

  const blob = new Blob([pdfBytes], { type: 'application/pdf' })

  const blobUrl = URL.createObjectURL(blob)
  window.open(blobUrl, '_blank')

  // saveAs(blob, 'medical_record.pdf')
}
