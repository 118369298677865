import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

export interface Organization {
  total: number
  items: any[]
  data: any
}

const initialState: Organization = {
  total: 0,
  items: [],
  data: {}
}

export const getOrganizationTree = createAsyncThunk('organization/getOrganizationTree', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('department/organization')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getAllDepartments = createAsyncThunk('organization/getAllDepartments', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('department/all')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

interface AddDepartmentDto {
  name: string
  description?: string
}
export const addDepartment = createAsyncThunk('organization/addDepartment', async (payload: AddDepartmentDto, { rejectWithValue }) => {
  try {
    const response = await api.post('department', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const editDepartment = createAsyncThunk('organization/editDepartment', async (payload: any, { rejectWithValue }) => {
  try {
    const { id, ...rest } = payload
    const response = await api.put(`department/${id}`, rest)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const deleteDepartment = createAsyncThunk('organization/deleteDepartment', async (id: number, { rejectWithValue }) => {
  try {
    const response = await api.delete(`department/${id}/permanently`)
    if (response) {
      return id
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const organizationSlice = createSlice({
  name: 'organization',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getAllDepartments.fulfilled, (state, action) => {
        const { total, items } = action.payload as any
        return {
          ...state,
          total,
          items
        }
      })
      .addCase(addDepartment.fulfilled, (state, action) => {
        ;(action.payload as any).doctorCount = 0
        state.items.unshift(action.payload)
      })
      .addCase(editDepartment.fulfilled, (state, action) => {
        const index = state.items.findIndex((item) => item.id === (action.payload as any).id)
        if (index > -1) {
          state.items[index] = action.payload
        }
      })
      .addCase(deleteDepartment.fulfilled, (state, action) => {
        state.items = state.items.filter((item) => item.id !== action.payload)
      })
  }
})

// export const { setFilter } = organizationSlice.actions
export default organizationSlice.reducer
