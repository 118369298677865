import { App, Button, Image } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import type { RootState } from '@/store'

import type { MedicalFormState } from '../../common/constant'
import { generatePDF } from '../../common/pdfUtils'
import { getMedicalById } from '../../reducers/medicalReducer'
import Page1 from './Page1'
import Page2 from './Page2'
import Page3 from './Page3'
import Page4 from './Page4'
import Page5 from './Page5'
import Page6 from './Page6'
import Page7 from './Page7'
import Page8 from './Page8'
import Page9 from './Page9'
import Page10 from './Page10'

const DetailLayout: React.FC = () => {
  const dispatch = useDispatch<any>()
  const [searchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()
  const { message } = App.useApp()

  const page = searchParams.get('page') || '1'
  const navigate: NavigateFunction = useNavigate()
  const totalPages = 10
  const goToPage = (pageNumber: string) => {
    if (pageNumber === page) return
    navigate(`/medical-records/${id}?page=${pageNumber}`)
  }
  const data: MedicalFormState = useSelector((state: RootState) => state.medical.detail)
  const detail = useSelector((state: RootState) => state.medical.allDetail)

  const currentInfo = useSelector((state: RootState) => state.auth.user)

  const handleExport = async () => {
    try {
      const templateUrl = `${window.location.origin}/template.pdf`

      let signature = null
      let headName = null
      if (currentInfo?.profile?.profilePicture && currentInfo?.doctor?.isHead) {
        signature = `${process.env.REACT_APP_BASE_API_URL}/uploads/profile/${currentInfo?.profile?.profilePicture}`
        headName = currentInfo?.doctor?.name
      }

      await generatePDF(data, templateUrl, signature, headName, detail.media)
    } catch (error) {}
  }

  const fetchData = async () => {
    try {
      await dispatch(getMedicalById(id as string))
    } catch (error) {}
  }

  const handleVideoPreview = async (fileUrl: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_API_URL}${fileUrl}`)
      const blob = await response.blob()
      const objectUrl = URL.createObjectURL(blob)

      window.open(objectUrl, '_blank')

      setTimeout(() => URL.revokeObjectURL(objectUrl), 100)
    } catch (error) {
      message.error('Không thể xem video')
    }
  }

  const pages = Array.from({ length: totalPages }, (_, i) => i + 1)

  const renderPage = () => {
    switch (page) {
      case '1':
        return <Page1 />
      case '2':
        return <Page2 />
      case '3':
        return <Page3 />
      case '4':
        return <Page4 />
      case '5':
        return <Page5 />
      case '6':
        return <Page6 />
      case '7':
        return <Page7 />
      case '8':
        return <Page8 />
      case '9':
        return <Page9 />
      case '10':
        return <Page10 />
      default:
        return <Page1 />
    }
  }

  useEffect(() => {
    fetchData()
  }, [id])

  return (
    <div className='form-medical'>
      <div className='flex justify-between'>
        <div className='flex justify-center space-x-4'>
          {pages.map((pageNumber) => (
            <Button
              key={pageNumber}
              onClick={() => goToPage(pageNumber.toString())}
              type={page === pageNumber.toString() ? 'primary' : 'default'}
              className='w-8'
            >
              {pageNumber}
            </Button>
          ))}
        </div>
        <div className='flex justify-end'>
          <Button onClick={() => navigate(`edit?page=${page}`)}>Chỉnh sửa</Button>

          <Button type='primary' className='ml-4' onClick={handleExport}>
            Xuất
          </Button>
        </div>
      </div>

      <div className='mt-4'>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {detail.media &&
              detail.media.length > 0 &&
              detail.media.map((media: any) => {
                const isVideoFile = media.fileType === 'mp4'

                return (
                  <div key={media.id}>
                    {isVideoFile ? (
                      <Button onClick={() => handleVideoPreview(media.fileUrl)} style={{ width: 100, height: 60 }}>
                        Xem video
                      </Button>
                    ) : (
                      <Image width={100} height={60} src={`${process.env.REACT_APP_BASE_API_URL}${media.fileUrl}`} alt={media.fileName} preview />
                    )}
                  </div>
                )
              })}
          </div>
        </div>
      </div>

      {renderPage()}
    </div>
  )
}

export default DetailLayout
