import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import api from '@/common/api'

export interface State {
  codeFormat: {
    prefix: string
    number: number
  }
  doctorText: {
    intern: string
    doctor: string
    head: string
  }
}

const initialState: State = {
  codeFormat: {
    prefix: '',
    number: 0
  },
  doctorText: {
    intern: '',
    doctor: '',
    head: ''
  }
}

/*
 *****************************************
 *
 *
 */

export const getCodeFormat = createAsyncThunk('settings/getCodeFormat', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('settings/codeFormat')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateCodeFormat = createAsyncThunk('settings/updateCodeFormat', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.put('settings/codeFormat', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const getDoctorText = createAsyncThunk('settings/getDoctorText', async (_, { rejectWithValue }) => {
  try {
    const response = await api.get('settings/doctorText')
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const updateDoctorText = createAsyncThunk('settings/updateDoctorText', async (payload: any, { rejectWithValue }) => {
  try {
    const response = await api.put('settings/doctorText', payload)
    if (response) {
      return response
    }
    return {}
  } catch (error: any) {
    return rejectWithValue(error?.response?.data)
  }
})

export const slice = createSlice({
  name: 'settings',

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getCodeFormat.fulfilled, (state, action) => {
        state.codeFormat = action.payload as any
      })
      .addCase(updateCodeFormat.fulfilled, (state, action) => {
        state.codeFormat = action.payload as any
      })
      .addCase(getDoctorText.fulfilled, (state, action) => {
        const { value } = action.payload as any
        state.doctorText = value
      })
      .addCase(updateDoctorText.fulfilled, (state, action) => {
        state.doctorText = action.payload as any
      })
  }
})

// export const { setFilter } = slice.actions
export default slice.reducer
