import { Button, Form, Input, message, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { Outlet, useNavigate } from 'react-router-dom'

import { ROLE } from '@/common/enum'
import { getDoctorText } from '@/modules/setting/reducers/settingReducer'
import type { RootState } from '@/store'

import { createDoctor, fetchDepartments } from '../../reducers/doctorReducer'

const { Option } = Select

const AddDoctor: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const [form] = Form.useForm()
  const departments = useSelector((state: RootState) => state.doctor.departments)
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector((state: RootState) => state.auth.user)
  const docetText = useSelector((state: RootState) => state.setting.doctorText)

  const isAdmin = currentUser?.role === ROLE.ADMIN || currentUser?.role === ROLE.SUPERADMIN

  const handleFinish = async (values: any) => {
    if (values.doctorRole === 'head') {
      values.isHead = true
    } else if (values.doctorRole === 'intern') {
      values.isIntern = true
    }

    delete values.doctorRole

    setLoading(true)
    try {
      await dispatch(createDoctor({ ...values, departmentId: Number(values.departmentId) })).unwrap()
      message.success('Bác sĩ đã được tạo thành công')
      form.resetFields()
      setTimeout(() => {
        navigate('/doctors')
      }, 2000)
    } catch (error) {
      message.error('Đã xảy ra lỗi khi tạo bác sĩ')
    } finally {
      setLoading(false)
    }
  }

  const fetchAllDepartments = async () => {
    try {
      await dispatch(fetchDepartments())
      await dispatch(getDoctorText())
    } catch (error) {
      message.error('Đã xảy ra lỗi khi tải danh sách phòng ban')
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> & { target: HTMLInputElement & { value: string } }) => {
    const sanitizedValue = e.target.value.toLowerCase().replace(/[^a-z0-9]/g, '')
    form.setFieldsValue({ username: sanitizedValue })
  }

  useEffect(() => {
    fetchAllDepartments()
  }, [])

  return (
    <div className='container mx-auto p-4'>
      <Outlet />
      <Form form={form} onFinish={handleFinish} layout='vertical'>
        <div className='mb-4 flex items-center justify-between'>
          <h1 className='m-0 text-2xl font-bold'>Tạo Bác Sĩ</h1>
          <Form.Item className='mb-0'>
            <Button type='primary' htmlType='submit' loading={loading}>
              Tạo Bác Sĩ
            </Button>
          </Form.Item>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <Form.Item name='name' label='Tên Bác Sĩ' rules={[{ required: true, message: 'Vui lòng nhập tên bác sĩ' }]}>
            <Input />
          </Form.Item>
        </div>
        <div className='grid grid-cols-2 gap-4'>
          <Form.Item name='username' label='Username' rules={[{ required: true, message: 'Vui lòng nhập Username' }]}>
            <Input onChange={handleChange} />
          </Form.Item>
          <Form.Item name='password' label='Password' rules={[{ required: true, message: 'Vui lòng nhập Password' }]}>
            <Input.Password />
          </Form.Item>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <Form.Item name='doctorRole' initialValue='doctor' label='Chức vụ' rules={[{ required: true, message: 'Vui lòng chọn chức vụ' }]}>
            <Select placeholder='Chọn chức vụ'>
              {isAdmin && <Option value='head'>{docetText.head}</Option>}
              <Option value='doctor'>{docetText.doctor}</Option>
              <Option value='intern'>{docetText.intern}</Option>
            </Select>
          </Form.Item>
          <Form.Item name='specialization' label='Chuyên Khoa (Không bắt buộc)'>
            <Input />
          </Form.Item>
        </div>

        <div className='grid grid-cols-2 gap-4'>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              { type: 'email', message: 'Email không hợp lệ' },
              { required: true, message: 'Vui lòng nhập Email' }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name='phoneNumber' label='Số Điện Thoại (Không bắt buộc)'>
            <Input />
          </Form.Item>
        </div>
        <Form.Item name='departmentId' label='Phòng Ban' rules={[{ required: true, message: 'Vui lòng chọn phòng ban' }]}>
          <div className='flex gap-4'>
            <Select placeholder='Chọn phòng ban' onChange={(value) => form.setFieldsValue({ departmentId: value })}>
              {departments &&
                departments?.map((item: any) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>

            <Button type='primary' htmlType='button' onClick={() => navigate('add-department')}>
              Thêm Phòng Ban
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AddDoctor
