import React from 'react'

import SettingIndex from './SettingIndex'

export const SettingRoutes = [
  {
    path: 'settings',
    element: <SettingIndex />
  }
]
